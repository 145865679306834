import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { ICtasBackgroundImage } from './CtasBackgroundImage.types'
import * as scss from './CtasBackgroundImage.module.scss'

const CtasBackgroundImage: React.FC<ICtasBackgroundImage> = ((props) => {
  const {
    primary: {
      content,
      image
    }
  } = props.data

  if (
    !content.richText.length ||
    !image.localFile
  ) return

  return (
    <div className={scss.root}>
      <div className={scss.wrapper}>
        <div
          className={`${scss.image} g-width`}
          // style={{
          //   backgroundImage: `linear-gradient(0deg, rgba(35, 34, 35, 0.64), rgba(35, 34, 35, 0.64))`
          // }}
          >
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div className={scss.content}>
          <div>
            <DefaultRichText text={content}/>
          </div>
        </div>
      </div>
    </div>
  )
})

export { CtasBackgroundImage }

import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'
import gsap from 'gsap'

import { useBreakpoints } from 'hooks/useMediaQuery'

import { useStoreOverlayMenu } from 'state/Global'
import linkResolver from 'utils/linkResolver'

import { Accordion } from 'components/common/Accordion/Accordion'

import { INavItem } from '../NavItem.types'
import * as scss from "./NavItem.overlay.module.scss"

const NavItemOverlay: React.FC<INavItem> = (({
  data: {
    id,
    primary,
    primary: {
      uid,
      // uid,
      label
    },
    items
  }
}) => {

  const [toggle, setToggle] = useState(false)
  const [tl] = useState(() => gsap.timeline({ paused: true }))

  const refIsInit = useRef<boolean>(false)
  const refRoot = useRef<HTMLDivElement>(null)
  const refArrow = useRef<HTMLSpanElement>(null)
  const refTitle = useRef<HTMLDivElement>(null)
  const refTitleWrapper = useRef<HTMLDivElement>(null)
  const refLinksWrapper = useRef<HTMLDivElement>(null)

  const { overlayOpen, open, SET_OPEN, navItemOverlayTarget } = useStoreOverlayMenu()
  const { isLg } = useBreakpoints()

  const htmlProperties = {
    'parent': {
      'active': {
        'name': '--o-inactive',
        'value': '1.0'
      },
      'inactive': {
        'name': '--o-inactive',
        'value': '0.48'
      },
    },
    'child': {
      'active': {
        'name': '--o-child-inactive',
        'value': '1.0'
      },
      'inactive': {
        'name': '--o-child-inactive',
        'value': '0.48'
      }
    }
  }

  const handleClick = (target: string) => {
    document.documentElement.style.setProperty(htmlProperties[target].inactive.name, htmlProperties[target].inactive.value)
    refTitle.current.setAttribute('data-h', toggle ? 'false' : 'true')
    setToggle((toggle: boolean) => !toggle)
    if (toggle) SET_OPEN({ status: false, id: '' })
    if (!toggle) SET_OPEN({ status: true, id: id })
  }

  useEffect(() => {
    if (navItemOverlayTarget === uid) {
      setToggle(true)
      SET_OPEN({ status: true, id: id })
    }
  }, [navItemOverlayTarget])

  useEffect(() => {
    if (open.id !== id && open.status) {
      setToggle(false)
      refTitle.current.setAttribute('data-h', 'false')
    }
  }, [open])

  const handleMouseEnter = (target: string) => {
    document.documentElement.style.setProperty(htmlProperties[target].inactive.name, htmlProperties[target].inactive.value)
    refTitle.current.setAttribute('data-h', 'true')
    if (isLg) {
      setToggle(true)
      SET_OPEN({ status: true, id: id })
    }
  }
  const handleMouseLeave = (target: string) => {
    if (isLg) {
    } else {
      if (open.status && open.id !== id) {
        document.documentElement.style.setProperty(htmlProperties[target].inactive.name, htmlProperties[target].inactive.value)
        refTitle.current.setAttribute('data-h', 'false')
      }
      if (!open.status) {
        document.documentElement.style.setProperty(htmlProperties[target].active.name, htmlProperties[target].active.value)
        refTitle.current.setAttribute('data-h', 'false')
      }
    }
  }

  const handleMouseLeaveDesktop = () => {
    if (!isLg) return
    setToggle(false)
    SET_OPEN({ status: false, id: '' })
    document.documentElement.style.setProperty(htmlProperties['parent'].active.name, htmlProperties['parent'].active.value)
    refTitle.current.setAttribute('data-h', 'false')
  }

  useEffect(() => {
    tl.to(refArrow.current, { rotate: 180 }, 0)
    isLg && tl.to(refLinksWrapper.current, { autoAlpha: 1, pointerEvents: 'auto' }, 0)
  }, [refArrow, refLinksWrapper])

  useEffect(() => {
    if (!refIsInit.current) return
    if (tl.paused()) tl.play()
    else tl.reversed(!toggle)
  }, [toggle])

  useEffect(() => {
    if (overlayOpen && toggle) {
      setToggle((toggle: boolean) => !toggle)
      SET_OPEN({ status: false, id: '' })
      document.documentElement.style.setProperty('--o-inactive', "1.0")
      refTitle.current.setAttribute('data-h', 'false')
    }
  }, [overlayOpen])

  useLayoutEffect(() => {
    document.documentElement.style.setProperty('--nav-item-overlay-height', `${refTitleWrapper.current.getBoundingClientRect().height}px`)
  }, [])

  useEffect(() => {
    refIsInit.current = true
  } ,[])

  return (
    <div
      className={scss.root}
      ref={refRoot}
    >
      <div
        ref={refTitleWrapper}
        className={scss.title}
        onClick={() => handleClick('parent')}
        // onMouseEnter={() => handleMouseEnter('parent')}
        // onMouseLeave={() => handleMouseLeave('parent')}
      >
        <div
          data-h='false'
          ref={refTitle}
          className={scss.title__wrapper}
        >
          <RichText render={label.richText} />
          <span className={scss.title__count}>{items.length}</span>
        </div>
        <span className={scss.arrow} ref={refArrow} />
      </div>
      <Accordion className={scss.links} timeline={tl}>
        <div
          // onMouseLeave={handleMouseLeaveDesktop}
          ref={refLinksWrapper}
          className={scss.links__wrapper}
        >
          { items.map(( { link_label, link_url }, index ) => (
            <SubLink 
              key={`navItemOverlay--${id}--${index}`}
              link_label={link_label}
              link_url={link_url}
            />
          ))}
        </div>
      </Accordion>
    </div>
  )
})

export { NavItemOverlay }

const SubLink = ({ link_label, link_url }) => {
  const { overlayOpen, SET_OVERLAY } = useStoreOverlayMenu()
  const refLink = useRef(null)

  const handleMouseEnter = () => {
    document.documentElement.style.setProperty('--o-child-inactive', '0.48')
    refLink.current.setAttribute('data-child-h', 'true')
  }

  const handleMouseLeave = () => {
    document.documentElement.style.setProperty('--o-child-inactive', '1.0')
    refLink.current.setAttribute('data-child-h', 'false')
  }
  3
  const handleClick = () => {
    SET_OVERLAY(overlayOpen ? false : true)
    document.documentElement.style.setProperty('--o-child-inactive', '1.0')
    refLink.current.setAttribute('data-child-h', 'false')
  }

  return (
    <Link
      ref={refLink}
      to={linkResolver(link_url)}
      data-child-h='false'
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={scss.links__link}
    >
    <RichText render={link_label.richText} />
  </Link>
  )
}
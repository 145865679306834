import React from 'react'
import { INavItem } from './NavItem.types'
import { NavItemOverlay } from './overlay/NavItem.overlay'
import { NavItemDefault } from './default/NavItem.default'

const NavItem: React.FC<INavItem> = ((props) => {
  const { variation } = props

  if (variation === 'overlay') {
    return (
      <NavItemOverlay data={props.data}/>
    )
  } else {
    return (
      <NavItemDefault data={props.data}/>
    )
  }
})

export { NavItem }

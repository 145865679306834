import React, {
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  Fragment,
} from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useBreakpoints } from 'hooks/useMediaQuery'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IColumnIconContent, TCardHandle } from './ColumnIconContent.types'
import * as scss from './ColumnIconContent.module.scss'

const ColumnIconContent: React.FC<IColumnIconContent> = ((props) => {
  const { id } = props
  const {
    primary: {
      content,
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return
  const { isLg } = useBreakpoints()
  const refRoot = useRef<HTMLDivElement>(null)
  const refsCards = useRef<any>([])

  const createScrollTrigger = () => {
    let cards = refsCards.current.map((item) => item.root)
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: 'top center',
        end: 'center center',
        scrub: 2,
      }
    })
    tl.to(cards, {
      y: 0,
      stagger: {
        amount: 0.5
      }
    })
    ScrollTrigger.refresh()
  }


  useLayoutEffect(() => {
    isLg && createScrollTrigger()
    return () => isLg && ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [isLg])

  return (
    <div className={scss.root} ref={refRoot}>
      <div className={`g-width`}>

        <div className={scss.content}>
          <DefaultRichText text={content}/>
        </div>

        <div className={`${scss.cards} `}>
          { items.map(({ content, image }, index) =>
              <Card
                key={`ColumnIconContent--${id}--${index}`}
                content={content}
                image={image}
                ref={(r) => (refsCards.current[index] = r)}
              />
            )
          }
        </div>

      </div>
    </div>
  )
})

export { ColumnIconContent }

const Card: ForwardRefExoticComponent<TCardHandle> = forwardRef((props, ref) => {
  const { content, image } = props
  const refRoot = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    root: refRoot.current
  }))

  return (
    <div className={scss.card} ref={refRoot}>
      <div>
        <div className={scss.card__image}>
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div><DefaultRichText text={content}/></div>
      </div>
    </div>
  )
})

import React from "react"
import { graphql } from "gatsby"

import { SEO } from 'components/SEO/Seo'
import SliceZone from "components/common/sliceZone/SliceZone"

import { IHome } from "./Home.types"
import * as scss from './Home.module.scss'

const Home: React.FC<IHome> = ({ data }) => {
  const { page } = data
  return (
    <div className={scss.root}>
      { data.page && data.page.data.body && <SliceZone data={page.data.body}/> }
    </div>
  )
}

export default Home

export const Head = ({ 
  data: {
    page: {
      data: {
        meta_title = 'default meta title',
        meta_description = 'default meta description'
      }
    }
  }
}) => (
  <SEO
    title={meta_title}
    description={meta_description}
  />
)

export const query = graphql`
  query homePage($uid: String!, $lang: String!) {
    page : prismicHome(uid: {eq: $uid}, lang: {eq: $lang}) {
      data {
        meta_description
        meta_title
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...FPrismicHomeDataBodyHero
          ...FPrismicHomeDataBodyHorizontalSlideshow
          ...FPrismicHomeDataBodyCircleLogosContent
          ...FPrismicHomeDataBodyRowContentIcon
          ...FPrismicHomeDataBodyTitleRowContent
          ...FPrismicHomeDataBodyContentBackgroundImageMap
          ...FPrismicHomeDataBodyTestimonialsRowVideos           
          ...FPrismicHomeDataBodyTestimonialsCardsSlider
          ...FPrismicHomeDataBodyCtasBackgroundImage
        }
      }
    }
  }
`

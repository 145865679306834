import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'

import SCROLL from 'utils/scroll'
import { useBreakpoints } from 'hooks/useMediaQuery'
import { IAccordion } from "./Accordion.types"
import * as scss from './Accordion.module.scss'

export const Accordion: React.FC<IAccordion> = ({
  children,
  className,
  timeline
}) => {

  const refAnswer = useRef<HTMLDivElement>()
  const { isLg } = useBreakpoints()
  useEffect(() => {
    if(!refAnswer.current) return

    timeline.to(refAnswer.current, {
      height: refAnswer.current.style.height === "auto" ? 0 : "auto",
      marginTop: refAnswer.current.style.height === "auto" ? 0 : isLg ? 0 : "32px",
    },0)

  }, [refAnswer])

  useEffect(() => {
    return () => {
      gsap.set(refAnswer.current, { opacity: 0 })
    }
  }, [])

  return (
    <div className={`${scss.root} ${className}`} ref={refAnswer}>
      {children}
    </div>
  )
}

// extracted by mini-css-extract-plugin
export var content = "VerticalScrollingContentImage-module--content--12488";
export var root = "VerticalScrollingContentImage-module--root--ba7ba";
export var root__dark = "VerticalScrollingContentImage-module--root__dark--ce375";
export var verticalContent = "VerticalScrollingContentImage-module--verticalContent--3f79f";
export var verticalContent__content = "VerticalScrollingContentImage-module--verticalContent__content--9037d";
export var verticalContent__content__image = "VerticalScrollingContentImage-module--verticalContent__content__image--6f460";
export var verticalContent__content__item = "VerticalScrollingContentImage-module--verticalContent__content__item--ef80a";
export var verticalContent__illustration = "VerticalScrollingContentImage-module--verticalContent__illustration--17d55";
export var verticalContent__illustration__background = "VerticalScrollingContentImage-module--verticalContent__illustration__background--95550";
export var verticalContent__illustration__image = "VerticalScrollingContentImage-module--verticalContent__illustration__image--cf713";
export var verticalContent__illustration__wrapper = "VerticalScrollingContentImage-module--verticalContent__illustration__wrapper--5e3cc";
export var verticalContent__scrollBar = "VerticalScrollingContentImage-module--verticalContent__scrollBar--5d069";
export var verticalContent__scrollBar__track = "VerticalScrollingContentImage-module--verticalContent__scrollBar__track--a3624";
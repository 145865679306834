import React, { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IHero } from './Hero.types'
import * as scss from './Hero.module.scss'
import Balancer from 'react-wrap-balancer'
const Hero: React.FC<IHero> = ((props) => {
  const { id } = props
  const {
    primary: {
      height,
      content,
      image,
      image_mobile
    }
  } = props.data

  if (
    !content.richText.length ||
    !image.localFile
  ) return

  const refRoot = useRef<HTMLDivElement>(null)
  const refImage = useRef<HTMLDivElement>(null)

  console.log(image)

  const createScrollTrigger = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: 'top top',
        end: 'bottom center',
        scrub: 1,
      }
    })
    tl.to(refImage.current, { scale: 1.05 })
    ScrollTrigger.refresh()
  }
  useLayoutEffect(() => {
    createScrollTrigger()
    return () => ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [])

  return (
    <div className={`${scss.root} ${height && scss.root__full}`} ref={refRoot}>
      <div className={scss.wrapper}>
        <div className={scss.image} ref={refImage}>
          <GatsbyImage
            className={`${scss.gatsbyImage} ${image_mobile && image_mobile.localFile && scss.gatsbyImage__desktop}`}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
          {
            image_mobile && image_mobile.localFile && (
              <GatsbyImage
                className={`${scss.gatsbyImage} ${scss.gatsbyImage__mobile}`}
                image={getImage(image_mobile.localFile)}
                alt={image_mobile.alt ? image_mobile.alt : 'AC - Consulting'}
              />
            )
          }
        </div>
        <div className={`${scss.content} g-width`}>
          <div className={scss.content__wrapper}>
            <Balancer><DefaultRichText text={content}/></Balancer>
            {/* <DefaultRichText text={content}/> */}
          </div>
        </div>
      </div>
    </div>
  )
})

export { Hero }

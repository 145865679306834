import React from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'

import { INavItemFooter } from './NavItemFooter.types'
import * as scss from "./NavItemFooter.module.scss"

const NavItemFooter: React.FC<INavItemFooter> = ((props) => {

  const {
    data: {
      primary: {
        label
      },
      items
    }
  } = props

  return (
    <div className={`${scss.root} `}>
      {
        label.text && (
        <div className={scss.title}>
          {label.text}
        </div>
        )
      }
      <div className={scss.links}>
        { items.map(( { link_label, link_url }, index ) => (
          link_url.uid ? 
          (
            <Link
              key={`linkfooter--${index}`}
              to={linkResolver(link_url)}
            >
              {link_label.text}
            </Link>
          ) : (
            <div
              key={`linkfooter--${index}`}
            >
              {link_label.text}
            </div>
          )
        ))}
      </div>
    </div>
  )
})

export {NavItemFooter}
// extracted by mini-css-extract-plugin
export var bottom = "TestimonialsCardsSlider-module--bottom--7ad59";
export var card = "TestimonialsCardsSlider-module--card--4ced2";
export var card__details = "TestimonialsCardsSlider-module--card__details--96df7";
export var card__details__avatar = "TestimonialsCardsSlider-module--card__details__avatar--3b302";
export var card__details__infos = "TestimonialsCardsSlider-module--card__details__infos--e3e60";
export var card__details__infos__job = "TestimonialsCardsSlider-module--card__details__infos__job--3b531";
export var card__details__infos__name = "TestimonialsCardsSlider-module--card__details__infos__name--a9e7a";
export var card__quote = "TestimonialsCardsSlider-module--card__quote--7aa7e";
export var card__quoteSvg = "TestimonialsCardsSlider-module--card__quoteSvg--8f030";
export var card__wrapper = "TestimonialsCardsSlider-module--card__wrapper--651ef";
export var carousel = "TestimonialsCardsSlider-module--carousel--c6763";
export var navigation = "TestimonialsCardsSlider-module--navigation--4ca58";
export var navigation__next = "TestimonialsCardsSlider-module--navigation__next--3150d";
export var navigation__prev = "TestimonialsCardsSlider-module--navigation__prev--91e0a";
export var root = "TestimonialsCardsSlider-module--root--81268";
export var title = "TestimonialsCardsSlider-module--title--80fad";
export var top = "TestimonialsCardsSlider-module--top--f5582";
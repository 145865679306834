import React from 'react'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IContent } from './Content.types'
import * as scss from './Content.module.scss'

const Content: React.FC<IContent> = ((props) => {
  const { id } = props
  const {
    primary: {
      content,
    }
  } = props.data

  if (
    !content.richText.length
  ) return

  return (
    <div className={`${scss.root} g-width`}>
      <div className={scss.wrapper}>
        <DefaultRichText text={content}/>
      </div>
    </div>
  )
})

export { Content }

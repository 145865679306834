import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IHeroVariation1 } from './HeroVariation1.types'
import * as scss from './HeroVariation1.module.scss'
import Balancer from 'react-wrap-balancer'

const HeroVariation1: React.FC<IHeroVariation1> = ((props) => {
  const {
    primary: {
      height,
      content,
      image_1,
      image_2
    }
  } = props.data

  if (
    !content.richText.length
  ) return

  console.log(image_1.url)

  return (
    <div className={scss.root}>
      <div className={scss.wrapper}>
        <div className={scss.image}>
          <div className={scss.image__wrapper}>
            {
              image_1.localFile && (
                <div className={scss.image__item}>
                  <GatsbyImage
                    className={scss.gatsbyImage}
                    image={getImage(image_1.localFile)}
                    alt={image_1.alt ? image_1.alt : 'AC - Consulting'}
                  />
                  <img className={scss.mobileImage} src={image_1.url}/>
                </div>
              )
            }
            {
              image_2.localFile && (
                <div className={scss.image__item}>
                  <img className={scss.mobileImage} src={image_2.url}/>
                  <GatsbyImage
                    className={scss.gatsbyImage}
                    image={getImage(image_2.localFile)}
                    alt={image_2.alt ? image_2.alt : 'AC - Consulting'}
                  />
                </div>
              )
            }
          </div>
        </div>
        <div className={`${scss.content} g-width`}>
          <div className={scss.content__wrapper}>
            <Balancer><DefaultRichText text={content}/></Balancer>
            {/* <DefaultRichText text={content}/> */}
          </div>
        </div>
      </div>
    </div>
  )
})

export { HeroVariation1 }

import React, { ReactNode } from "react"
import { breadcrumbList, BreadcrumbListItem } from "./json-ld"
import { useSiteMetadata } from "hooks/useSiteMetaData"

type SEOProps = {
  title?: string
  description?: string
  pathname?: string
  image?: string
  noIndex?: boolean
  breadcrumbListItems?: BreadcrumbListItem[]
  children?: ReactNode
}

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  pathname,
  image,
  noIndex = false,
  breadcrumbListItems = [],
  children
}) => {
  const { siteTitleDefault, siteUrl, siteDescription, siteImage, twitter } = useSiteMetadata()

  const seo = {
    title: title || siteTitleDefault,
    description: description || siteDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${image || siteImage}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      {/* <html lang="en-US" /> */}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={twitter} />
      <meta name="creator" content="LekoArts" />
      <meta name="msapplication-TileColor" content="#0f172a" />
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      {breadcrumbListItems.length >= 1 && (
        <script type="application/ld+json">{JSON.stringify(breadcrumbList(breadcrumbListItems))}</script>
      )}
      { children }
    </>
  )
}
// extracted by mini-css-extract-plugin
export var circles = "CircleLogosContent-module--circles--ec221";
export var circles__circle1 = "CircleLogosContent-module--circles__circle1--9aa4d";
export var circles__circle1__logo = "CircleLogosContent-module--circles__circle1__logo--50d15";
export var circles__circle2 = "CircleLogosContent-module--circles__circle2--9a8f4";
export var circles__circle2__logo = "CircleLogosContent-module--circles__circle2__logo--7ee0a";
export var circles__circle3 = "CircleLogosContent-module--circles__circle3--60d85";
export var circles__circle3__dot1 = "CircleLogosContent-module--circles__circle3__dot1--a5071";
export var circles__circle3__logo = "CircleLogosContent-module--circles__circle3__logo--387cf";
export var circles__circle4 = "CircleLogosContent-module--circles__circle4--48af8";
export var circles__wrapper = "CircleLogosContent-module--circles__wrapper--90053";
export var content = "CircleLogosContent-module--content--bd7f8";
export var content__top = "CircleLogosContent-module--content__top--6edbe";
export var cta = "CircleLogosContent-module--cta--e719f";
export var dot = "CircleLogosContent-module--dot--83d36";
export var logo = "CircleLogosContent-module--logo--9d662";
export var root = "CircleLogosContent-module--root--1d25a";
export var root__dark = "CircleLogosContent-module--root__dark--31b5e";
export var rotating = "CircleLogosContent-module--rotating--02e17";
export var spin = "CircleLogosContent-module--spin--ac0f2";
export var wrapper = "CircleLogosContent-module--wrapper--a6068";
export var wrapper__dark = "CircleLogosContent-module--wrapper__dark--d28d5";
export var wrapper__reverse = "CircleLogosContent-module--wrapper__reverse--99a06";
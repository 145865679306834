import React,{Fragment} from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'

import { IFormInputField } from "./FormInputField.types"
import * as scss from './FormInputField.module.scss'

export const FormInputField: React.FC<IFormInputField> = (props) => {

  const {
    id,
    primary: {
      field_label,
      field_name,
      field_placeholder,
      field_type,
      required,
      width
    },
  } = props.data

  const { register, errors } = props

  // console.log(
  //   field_label,
  //   field_name,
  //   field_placeholder,
  //   field_type
  // )
  // console.log(width)

  const requiredField = required ? {
    required: field_placeholder,
  } : {}

  const requiredFieldEmail = required ? {
    required: field_placeholder,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    }
  } : {}

  return (
    <label htmlFor={field_type} className={`${scss.root} ${width ? scss.root__full : scss.root__half} ${errors[field_name] && scss.root__error}`}>
      <span className={scss.label}>{field_label} {required && <span className={scss.start}>*</span>}</span>
      <input
        autocomplete="off"
        // className={errors[field_name] ? 'error' : ''}
        className={`${scss.input}`}
        type={field_type}
        name={field_name}
        placeholder={field_placeholder}
        {...register(field_name, field_name === 'email' && required ? requiredFieldEmail : required ? requiredField : false )}
      />
  </label>
  )
}



// extracted by mini-css-extract-plugin
export var card = "Desktop-module--card--10d7b";
export var card__active = "Desktop-module--card__active--647a4";
export var card__content = "Desktop-module--card__content--ef04d";
export var card__content__job = "Desktop-module--card__content__job--a5411";
export var card__content__name = "Desktop-module--card__content__name--6106f";
export var card__video = "Desktop-module--card__video--51f5b";
export var card__video__placeholder = "Desktop-module--card__video__placeholder--76650";
export var card__video__playButton = "Desktop-module--card__video__playButton--7d3f0";
export var content = "Desktop-module--content--6e976";
export var content__details = "Desktop-module--content__details--9a605";
export var content__details__avatar = "Desktop-module--content__details__avatar--6a7a4";
export var content__details__infos = "Desktop-module--content__details__infos--4be60";
export var content__details__infos__job = "Desktop-module--content__details__infos__job--d3d3e";
export var content__details__infos__name = "Desktop-module--content__details__infos__name--6d61b";
export var content__quote = "Desktop-module--content__quote--0f5f7";
export var gatsbyImage = "Desktop-module--gatsbyImage--80207";
export var navigation = "Desktop-module--navigation--ff393";
export var navigation__next = "Desktop-module--navigation__next--2cd2c";
export var navigation__prev = "Desktop-module--navigation__prev--6d81a";
export var root = "Desktop-module--root--5db14";
export var videojs = "Desktop-module--videojs--084ee";
export var wrapper = "Desktop-module--wrapper--51dff";
export var wrapper__inner = "Desktop-module--wrapper__inner--b2f2b";
import React, { useRef, forwardRef, useImperativeHandle, ForwardRefRenderFunction, useLayoutEffect, useEffect } from 'react'
import gsap from 'gsap'
import { StaticImage } from "gatsby-plugin-image"

import { useBreakpoints } from 'hooks/useMediaQuery'
import SliceZone from 'components/common/sliceZone/SliceZone'
import backgroundShape from 'assets/images/background-shape-ocre.svg'
import { IOverlay, TOverlayHandle } from './Overlay.types'
import * as scss from "./Overlay.module.scss"

const Overlay: ForwardRefRenderFunction<TOverlayHandle, IOverlay> = ((props, ref) => {
  const { body } = props
  const refRoot = useRef<HTMLDivElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)
  const refBackgroundWhite = useRef<HTMLDivElement>(null)
  const refBackgroundOcre = useRef<HTMLDivElement>(null)
  const refBackgroundImage = useRef<HTMLDivElement>(null)
  const { isLg } = useBreakpoints()

  useImperativeHandle(ref, () => ({
    animateIn: animateIn
  }))

  const animateIn = () => {
    const tl = gsap.timeline()
    tl.to(refRoot.current, { autoAlpha: 1, pointerEvents: 'auto' })
    tl.to(refBackgroundWhite.current, {
      y: "50%",
      duration: 1,
      ease: "power1.inOut",
    }, 0.15)
    tl.to(refBackgroundOcre.current, {
      y: "0%",
      duration: 1,
      ease: "power1.inOut",
    }, 0.25)
    tl.to([refWrapper.current, refBackgroundImage.current], { autoAlpha: 1 })
    return tl
  }

  return (
    <div className={scss.root} ref={refRoot}>
      <span
        ref={refBackgroundWhite}
        className={`${scss.background} ${scss.background__white}`}
      />
      <span
        ref={refBackgroundOcre}
        className={`${scss.background} ${scss.background__ocre}`}
      />
      <div
        ref={refBackgroundImage}
        className={`${scss.background} ${scss.background__imageWrapper}`}>
          {
            isLg ? (
              <img
                className={scss.background__image}
                src={backgroundShape}
                alt="AC Consulting"
              />
            ) : 
            (
              <StaticImage
                layout='fullWidth'
                className={scss.background__image}
                src="../../../../assets/images/background-shape-ocre.svg"
                alt="AC Consulting"
              /> 
            )
          }
      </div>
      <div className={`${scss.wrapper} g-width`} ref={refWrapper}>
        <div data-lenis-prevent className={scss.wrapper__links}>
          <SliceZone data={body} variation='overlay'/>
        </div>
      </div>
    </div>
  )
})

export default forwardRef(Overlay)

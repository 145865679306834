import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
} from 'react'
import gsap from 'gsap'
import * as _ from 'lodash'
import ScrollTrigger from "gsap/ScrollTrigger"
import { useInView } from 'react-intersection-observer'
import { useBreakpoints } from 'hooks/useMediaQuery'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IVerticalScrollingSlider, IContent } from './VerticalScrollingSlider.types'
import SCROLL from 'utils/scroll'
import * as scss from './VerticalScrollingSlider.module.scss'

const VerticalScrollingSlider: React.FC<IVerticalScrollingSlider> = ((props) => {
  const {
    id,
    primary: {
      content,
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return
  const { isLg } = useBreakpoints()
  const refsSlide = useRef<any>([])
  const refsDot = useRef<any>([])
  const refSlideWrapper = useRef<HTMLDivElement>()
  const refslideInnerWrapper = useRef<HTMLDivElement>()
  const refNavigation = useRef<HTMLDivElement>()
  const refNavigationWrapper = useRef<HTMLDivElement>()
  const refCurrentDot = useRef(null)
  const refEnter = useRef(false)

  const handleOnEnter = (index) => {
    
    if (refCurrentDot.current) {
      refCurrentDot.current.classList.remove(scss.active)
    }
    refsDot.current[index] && refsDot.current[index].classList.add(scss.active)
    refCurrentDot.current = refsDot.current[index]
  }
  
  useLayoutEffect(() => {
    if (isLg) {
      gsap.set(refSlideWrapper.current, { height: window.innerHeight * (items.length)})
    }
  }, [isLg, SCROLL])


  const handleClick = (index) => {
    SCROLL.lenis.scrollTo(refSlideWrapper.current.offsetTop + ((window.innerHeight) * (index + 1)))
  }
  

  return (
    <div className={`${scss.root}`} > 
      <div>

        <div className={`${scss.content}`}>
          <DefaultRichText text={content}/>
        </div>


        <div className={scss.verticalContent} ref={refSlideWrapper}>

          <div className={scss.navigation} ref={refNavigation}>
            <div className={scss.navigation__wrapper} ref={refNavigationWrapper}>
              <div className={scss.navigation__dots}>
                { [...Array(items.length)].map((item, index) => (
                    <div
                      ref={(r) => (refsDot.current[index] = r)}
                      key={`verticalScrollContentDot--${index}`}
                      className={`${scss.navigation__dot}`}
                      onClick={() => handleClick(index)}
                    >
                      <span>{index + 1}</span>
                    </div>
                  )) 
                }
              </div>
            </div>
          </div>

          <div className={scss.verticalContent__wrapper} ref={refslideInnerWrapper}>
            {
              items.map((item, index) =>
                <Card
                  handleOnEnter={handleOnEnter}
                  ref={(r) => (refsSlide.current[index] = r)}
                  key={`verticalScrollingSlider--${id}--${index}`}
                  index={index}
                  item={item}
                  length={items.length}
                />
              )
            }
          </div>
        </div>

      </div>
    </div>
  )
})

export { VerticalScrollingSlider }


const Card: ForwardRefExoticComponent<IContent> = forwardRef((props, ref) => {
  const { content, image } = props.item
  const { length, handleOnEnter } = props
  const { isLg } = useBreakpoints()
  const refRoot = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    rootHeight: refRoot.current.getBoundingClientRect().height,
    root: refRoot.current
  }))

  const createScrollTrigger = () => {
    ScrollTrigger.create({
      id: `scroll ${props.index}`,
      trigger: refRoot.current,
      start: "center center", 
      end: "bottom center",
      onEnter: () => handleOnEnter(props.index),
      onEnterBack: () => handleOnEnter(props.index),
      // markers: true
    })
    ScrollTrigger.refresh()
  }


  useEffect(() => {
    isLg && createScrollTrigger()
    return () => isLg && ScrollTrigger.getById(`scroll ${props.index}`).kill()
  }, [isLg])


  return (
    <div ref={refRoot} className={scss.verticalContent__item} data-transform={props.index > 0 ? 'true' : false}>
      <div className={scss.verticalContent__content}>
        <div className={scss.verticalContent__content__index}>
          <div className={scss.verticalContent__content__index__wrapper}>
            <DefaultRichText text={props.item.index}/>
          </div>
        </div>
        <DefaultRichText text={content}/>
      </div>
      <div className={scss.verticalContent__image}>
        <GatsbyImage
          image={getImage(image.localFile)}
          alt={image.alt ? image.alt : 'AC - CONSULTING'}
        />
      </div>
    </div>
  )
})



import React, { Fragment, useEffect } from 'react'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useBreakpoints } from 'hooks/useMediaQuery'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import backgroundShape from 'assets/images/background-shape-noir-light.svg'
import { IRowContentIcon, ICard } from './RowContentIcon.types'
import * as scss from './RowContentIcon.module.scss'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'

const RowContentIcon: React.FC<IRowContentIcon> = ((props) => {
  const {
    id,
    primary: {
      content,
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return

  return (
    <div className={`${scss.root}`}>
      <div className={scss.wrapper}>
        <div className={`${scss.content} `}>
          <div className={scss.content__wrapper}>
            <DefaultRichText text={content}/>
          </div>
        </div>
        { items.map((item, index) => <Card key={`RowContentIcon__${id}--${index}`} item={item}/>) }
      </div>
    </div>
  )
})

export { RowContentIcon }

const Card: React.FC<ICard> = ({ item }) => {

  const {
    title,
    content,
    image,
    link_label,
    link_url
    } = item

    if (
      !title.richText.length ||
      !link_label.richText.length ||
      !link_url.uid &&
      !link_url.url ||
      !image.url
    ) return
  return (
    <div className={scss.card}>
      <div className={scss.card__wrapper}>
        <div className={scss.card__imageWrapper}>
          <span
            style={{
              WebkitMaskImage: `url(${image.url})`,
            }}
            className={scss.card__imageWrapper__image}
          />
        </div>
        <div className={scss.card__title}><DefaultRichText text={title}/></div>
        <div>
          <div className={scss.card__content}><DefaultRichText text={content}/></div>
          <div className={scss.card__link}>
            <DefaultLink link_label={link_label} link_url={link_url}/>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IContentSquarePictures } from './ContentSquarePictures.types'
import * as scss from './ContentSquarePictures.module.scss'

const ContentSquarePictures: React.FC<IContentSquarePictures> = ((props) => {
  const { id } = props
  const {
    primary: {
      content_left,
      content_right,
    },
    items
  } = props.data

  if (
    !content_left.richText.length ||
    !content_right.richText.length ||
    !items.length
  ) return

  return (
    <div className={scss.root}>
      <div className={`g-width`}>

        <div className={scss.content}>
          <div className={scss.content__wrapper}>
            <DefaultRichText text={content_left}/>
          </div>
          <div className={scss.content__wrapper}>
            <DefaultRichText text={content_right}/>
          </div>
        </div>

        <div className={`${scss.cards} `}>
          { items.map(({ content, image }, index) => <Card key={`ContentSquarePictures--${id}--${index}`} content={content} image={image}/>) }
        </div>

      </div>
    </div>
  )
})

export { ContentSquarePictures }

const Card = ({content, image}) => {
  return (
    <div className={scss.card}>
      <div>
        <div className={scss.card__image}>
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div><DefaultRichText text={content}/></div>
      </div>
    </div>
  )
}

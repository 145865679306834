import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { INumberContentColumn, ICard } from './NumberContentColumn.types'
import * as scss from './NumberContentColumn.module.scss'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'

const NumberContentColumn: React.FC<INumberContentColumn> = ((props) => {
  const {
    id,
    items
  } = props.data

  if (!items.length) return

  const refRoot = useRef<HTMLDivElement>(null)
  const [trigger, setTrigger] = useState(false)

  const createScrollTrigger = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: 'top center',
        end: 'bottom center',
        scrub: 1,
        onEnter: () => setTrigger(true),
        // markers: true
      }
    })
    tl.scrollTrigger.refresh()
  }

  useEffect(() => {
    createScrollTrigger()
    return () => ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [])

  return (
    <div className={`${scss.root} g-width`} >
      <div ref={refRoot}>
        <div className={scss.cards}>
          { items.map((item, index) => <Card key={`NumberContentColumn__${id}--${index}`} item={item} trigger={trigger} />) }
        </div>

      </div>
    </div>
  )
})

export { NumberContentColumn }

const Card: React.FC<ICard> = ({ item, trigger }) => {
  
  const {
    content,
    number,
    } = item

  if (
    !content.richText.length ||
    !number
  ) return

  const refNumber = useRef<HTMLDivElement>(null)
  const refNum = { val: 0 }

  useEffect(() => {
    if (trigger) {
      gsap.to(refNum, {
        val: number,
        duration: 2,
        onUpdate: () => {
          refNumber.current.innerHTML = `${Math.ceil(refNum.val)}`
        }
      })
    }
  }, [trigger])

  return (
    <div className={scss.card}>
      <div className={scss.card__number} ref={refNumber}>{Math.ceil(refNum.val)}</div>
      <div className={scss.card__content}>
        <DefaultRichText text={content}/>
      </div>
    </div>
  )
}

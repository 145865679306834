// extracted by mini-css-extract-plugin
export var card = "ContentVideosSlider-module--card--b76ca";
export var card__content = "ContentVideosSlider-module--card__content--281cf";
export var card__slide = "ContentVideosSlider-module--card__slide--c94ce";
export var card__video = "ContentVideosSlider-module--card__video--b48e0";
export var card__video__playButton = "ContentVideosSlider-module--card__video__playButton--248d4";
export var cards = "ContentVideosSlider-module--cards--cc1b7";
export var content = "ContentVideosSlider-module--content--be2d5";
export var cta = "ContentVideosSlider-module--cta--a7c2b";
export var gatsbyImage = "ContentVideosSlider-module--gatsbyImage--f6b9c";
export var navigation = "ContentVideosSlider-module--navigation--cccf9";
export var navigation__next = "ContentVideosSlider-module--navigation__next--2b8f4";
export var navigation__prev = "ContentVideosSlider-module--navigation__prev--2361c";
export var root = "ContentVideosSlider-module--root--57fba";
export var videojs = "ContentVideosSlider-module--videojs--b7204";
export var wrapper = "ContentVideosSlider-module--wrapper--ad2cf";
import React,{Fragment} from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'

import { IFormSubmitInput } from "./FormSubmitInput.types"
import * as scss from './FormSubmitInput.module.scss'

export const FormSubmitInput: React.FC<IFormSubmitInput> = (props) => {

  const {
    id,
    primary: {
      input_label,
      input_value,
      width
    },
  } = props.data

  const { register } = props
  return (
    <label className={scss.root}>
      <input
        type="submit"
        value={input_value}
      />
      <span><span></span></span>
    </label>
  )
}



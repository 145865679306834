import React, { Fragment } from "react"

import { ISliceZone } from "./SliceZone.types"
import { NavItem } from "./slices/NavItem/NavItem"
import { FormInputField } from "./formSlices/FormInputField/FormInputField"
import { FormRadioField } from "./formSlices/FormRadioField/FormRadioField"
import { FormSwitchField } from "./formSlices/FormSwitchField/FormSwitchField"
import { FormSelectField } from "./formSlices/FormSelectField/FormSelectField"
import { FormTextArea } from "./formSlices/FormTextArea/FormTextArea"
import { FormSubmitInput } from "./formSlices/FormSubmitInput/FormSubmitInput"

const FormSliceZone = ( { data, variation, register, errors, control } : ISliceZone ) => {

  const components = {
    "nav_item": NavItem,
    "form_input_field": FormInputField,
    "form_radio_field": FormRadioField,
    "form_switch_field": FormSwitchField,
    "form_select_field": FormSelectField,
    "form_text_area": FormTextArea,
    "form_submit_input": FormSubmitInput
  }
  
  const sliceZoneContent = data.map((slice, index) => {
    // console.log('=>', slice.slice_type, slice)
    const SliceComponent = components[slice.slice_type]
    if (SliceComponent) {
      return (
        <SliceComponent
          data={slice}
          key={`slice-${slice.id}`}
          id={slice.id}
          register={register}
          errors={errors}
          control={control}
        />
      )
    }
    return null
  })

  return <Fragment>{sliceZoneContent}</Fragment>
}

export default FormSliceZone
import React, {
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  Fragment,
  useEffect,
} from 'react'
import { navigate } from 'gatsby'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { useBreakpoints } from 'hooks/useMediaQuery'
import RAF from 'utils/raf'
import SCROLL from 'utils/scroll'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { ITitleRowContent, ICard, TCardHandle } from './TitleRowContent.types'
import * as scss from './TitleRowContent.module.scss'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import linkResolver from 'utils/linkResolver'

const TitleRowContent: React.FC<ITitleRowContent> = ((props) => {
  const {
    id,
    primary: {
      content,
      link_label,
      mobile_link_label,
      link_url
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return
  const { isLg } = useBreakpoints()
  const refsCards = useRef<any>([])
  const refRoot = useRef<HTMLDivElement>(null)
  const refCardsWrapper = useRef<HTMLDivElement>(null)
  const refLink = useRef<HTMLDivElement>(null)
  const refBounding = useRef(null)

  const refPos = useRef({
    x:  0,
    y:  0
  })
  const refMouse = useRef({
    x: refPos.current.x,
    y: refPos.current.y
  })
  const refQuickSetter = useRef({
    xSet: null,
    ySet: null
  })
  
  const createScrollTrigger = () => {
    gsap.set(refCardsWrapper.current, { height: `${50 * items.length - 1}vh` })

    let cards = refsCards.current.map((item) => item.root)
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: () => `-200px center`,
        end: 'bottom center',
        scrub: 1,
      }
    })
    tl.to(cards, {
      y: 0,
      stagger: {
        amount: 0.5
      }
    })
    ScrollTrigger.refresh()
  }


  useLayoutEffect(() => {
    refPos.current.x = window.innerWidth / 2
    refPos.current.y = window.innerHeight / 2
    
    isLg && createScrollTrigger()
    return () => isLg && ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [isLg])

  useLayoutEffect(() => {
    const ball = refLink.current

    refQuickSetter.current.xSet = gsap.quickSetter(ball, "x", "px");
    refQuickSetter.current.ySet = gsap.quickSetter(ball, "y", "px");
    refBounding.current = refRoot.current.getBoundingClientRect()

    RAF.subscribe(`TitleRowContent-card--${id}`, handleUpdate)

    return () => RAF.unsubscribe(`TitleRowContent-card--${id}`)
  }, [])

  useEffect(() => {
  }, [])

  const handleMouseMove = (e) => {
    const bouding = refRoot.current.getBoundingClientRect()
    refMouse.current.x =  e.clientX - bouding.left
    refMouse.current.y = e.clientY - bouding.top
  }
  const handleMouseEnter = () => {
    gsap.to(refLink.current, { scale: 1, autoAlpha: 1})
  }
  const handleMouseLeave = () => {
    gsap.to(refLink.current, { scale: 0, autoAlpha: 0})
  }

  const handleUpdate = () => {
    const dt = 1.0 - Math.pow(1.0 - 0.1, gsap.ticker.deltaRatio()); 
  
    refPos.current.x += (refMouse.current.x - refPos.current.x) * dt;
    refPos.current.y += (refMouse.current.y - refPos.current.y) * dt;

    refQuickSetter.current.xSet(refPos.current.x - 75)
    refQuickSetter.current.ySet(refPos.current.y - 75)
  }
  const handleClick = () => {
    if (!isLg) return
    let li = linkResolver(link_url)
    navigate(li)
  }

  return (
    <div className={`${scss.root} g-width`} ref={refRoot} onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
      <div>
        <div className={scss.content}>
          <DefaultRichText text={content}/>
        </div>
        <div className={scss.cards} ref={refCardsWrapper}>
          <div className={scss.cards__wrapper}>
            { items.map((item, index) =>
                <Card
                  key={`TitleRowContent__${id}--${index}`}
                  item={item}
                  ref={(r) => (refsCards.current[index] = r)}
                  index={index}
                />
              )
            }
          </div>
        </div>
        <div className={scss.mobileLink}>
          <DefaultLink link_label={mobile_link_label} link_url={link_url}/>
        </div>
      </div>

      <div className={scss.follower} ref={refLink}>
        <div className={scss.follower__wrapper}>
          <DefaultLink link_label={link_label} link_url={link_url}/>
        </div>
      </div>

    </div>
  )
})

export { TitleRowContent }

const Card: ForwardRefExoticComponent<TCardHandle> = forwardRef((props, ref) => {

  const {
    index,
    content,
    id
    } = props.item

  const refRoot = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    root: refRoot.current
  }))

  return (
    <div className={scss.card} ref={refRoot}>
      <div>
        <div className={scss.card__content} >
          <div className={scss.card__index}><DefaultRichText text={index}/></div>
          <DefaultRichText text={content}/>
        </div>
      </div>
    </div>
  )
})

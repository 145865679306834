import { graphql, useStaticQuery } from "gatsby"
import { find } from "lodash"
import { ImageDataLike } from "gatsby-plugin-image"
import { RichTextBlock } from "prismic-reactjs"

export type TGatsbyImage = {
  alt: string
  url?: string
  localFile?: ImageDataLike
}
interface IGetFooter  {
  lang: string
}

interface IGetFooterQuery  {
  allPrismicFooter: {
    nodes: {
      lang: string
      data: {
        bottom_text: {
          richText: RichTextBlock[]
        }
        logo: {
          alt: string
          url: string
          localFile: TGatsbyImage
        }
        body: {
          id: string
          slice_type: string
        }[]
      }
    }[]
  }
}

export const getFooter = ( { lang } : IGetFooter) => {
  const data = useStaticQuery<IGetFooterQuery>(graphql`
    query {
      allPrismicFooter(sort: {fields: lang, order: ASC}) {
        nodes {
          lang
          data {
            bottom_text {
              richText
              text
            }
            logo {
              alt
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            body {
              ... on PrismicFooterDataBodyNavItemFooter {
                ...FPrismicFooterDataBodyNavItemFooter
              }
            }
          }
        }
      }
    }
  `)
  return find(data.allPrismicFooter.nodes, (Footer) => Footer.lang === lang)
}

export const FPrismicFooterDataBodyNavItemFooter = graphql`
fragment FPrismicFooterDataBodyNavItemFooter on PrismicFooterDataBodyNavItemFooter {
  id
  slice_type
  primary {
    label {
      richText
      text
    }
  }
  items {
    link_label {
      richText
      text
    }
    link_url {
      uid
      lang
      type
    }
  }
}
`
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  useState
} from 'react'
import gsap from 'gsap'
import Slider from "react-slick"

import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IContentVideosSlider, TCardHandle } from './ContentVideosSlider.types'
import * as scss from './ContentVideosSlider.module.scss'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import { useBreakpoints } from 'hooks/useMediaQuery'

const ContentVideosSlider: React.FC<IContentVideosSlider> = (props) => {
  const {
    id,
    primary: {
      content,
      link_label,
      link_url
    },
    items
  } = props.data

  const refSlider = useRef<any>()
  const refsCards = useRef<any>([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const wrapIndex = gsap.utils.wrap(0, items.length, currentSlide)
  const { isLg } = useBreakpoints()

  const handleNext = () => {
    refSlider.current.slickNext()
  }
  const handlePrev = () => {
    refSlider.current.slickPrev()
  }

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1.25,
          dots: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  }

  useEffect(() => {
  }, [wrapIndex])

  console.log(link_label, link_url)

  return (
    <div className={scss.root}>

      <div className={`${scss.wrapper} g-width`}>

        <div className={scss.content}>
          <DefaultRichText text={content}/>
        </div>

        <div className={`${scss.cards}`}>
          <Slider {...settings} ref={refSlider}>
            { 
              items.map(({ content, link_cdn }, index) => 
              isLg ? (
                <Card
                  key={`TestimonialsRowVideos--${id}--${index}`}
                  ref={(r) => (refsCards.current[index] = r)}
                  index={index}
                  content={content}
                  link_cdn={link_cdn}
                />
                ): (
                  <CardMobile
                  key={`TestimonialsRowVideos--${id}--${index}`}
                  ref={(r) => (refsCards.current[index] = r)}
                  index={index}
                  content={content}
                  link_cdn={link_cdn}
                />
                )
              )
            }
          </Slider>

          <div className={scss.navigation}>
            <span
              onClick={handlePrev}
              className={scss.navigation__prev}
            />
            <span
              onClick={handleNext}
              className={scss.navigation__next}
            />
          </div>

        </div>

        {
          link_url &&
          link_url.uid && (
            <div className={scss.cta}>
              <DefaultLink link_label={link_label} link_url={link_url}/>
            </div>
          )
        }

      </div>

    </div>
  )
}

const CardMobile = (props) => {
  const {
    content,
    link_cdn,
  } = props


  const refCard = useRef<HTMLDivElement>(null)
  const refPlayButton = useRef<HTMLDivElement>(null)
  const refVideo = useRef<HTMLVideoElement>(null)
  const [play, setPlay] = useState(false)

  return (
    <div className={scss.card} ref={refCard} >
    <div className={scss.card__video} >
      <iframe src={link_cdn.url}/>
      {/* <video  id="introVideo" width="100%" height="100%" controls="controls" playsInline>
        <source src={`${link_cdn.url}#t=0.001`} type="video/mp4"/>
      </video> */}
      {/* <span
        className={scss.card__video__playButton}
        ref={refPlayButton}
      /> */}
    </div>
    <div className={scss.card__content}>
      <DefaultRichText text={content}/>
    </div>
  </div>
  )
}

const Card: ForwardRefExoticComponent<TCardHandle> = forwardRef((props, ref) => {
  const {
    content,
    link_cdn,
  } = props


  const refCard = useRef<HTMLDivElement>(null)
  const refPlayButton = useRef<HTMLDivElement>(null)
  const refVideo = useRef<HTMLVideoElement>(null)
  const [play, setPlay] = useState(false)

  useImperativeHandle(ref, () => ({
  }))


  const handlePlay = () => {
    setPlay(play => play ? false : true)
  }

  const startVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 0, pointerEvents: 'none'},0)
    timeline.add(() => {
      refVideo.current.src += '&autoplay=1'

      // refVideo.current && refVideo.current.play()
      // refVideo.current.muted = false
    },0)
  }

  const resetVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 1, pointerEvents: 'all'},0)
    timeline.add(() => {
      if (!refVideo.current) return
      refVideo.current.src = link_cdn.url

      // refVideo.current.pause()
      // refVideo.current.currentTime = 0
      // refVideo.current.muted = true

    },0)
  }

  useEffect(() => {
    play ? startVideo() : resetVideo()
  }, [play])

  return (
    <div
      className={scss.card}
      ref={refCard}
      onClick={handlePlay}
    >
      <div
        className={scss.card__video}
      >
      <iframe ref={refVideo} src={link_cdn.url}/>

        {/* <video
          ref={refVideo}
          autoPlay={false}
          playsInline
          muted
        >
          { link_cdn && link_cdn.url && <source src={`${link_cdn.url}#t=0.001`} type="video/mp4"/> }
        </video> */}
        {/* <span
          className={scss.card__video__playButton}
          ref={refPlayButton}
        /> */}
      </div>
      <div className={scss.card__content}>
        <DefaultRichText text={content}/>
      </div>
    </div>
  )
})

export { ContentVideosSlider }


const linkResolver = (doc) => {
  if (!doc.type || !doc.lang || !doc.uid) return '/'

  switch (doc.type) {

    case 'home': {
      let path = doc.lang === 'fr-fr' ? '/' : `/${doc.lang}`
      return path
    }

    case 'page_template': 
    case 'product_template': {
      let path = doc.lang === 'fr-fr' ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
      return path
    }

    default:
      return '/'
  }
}

export default linkResolver
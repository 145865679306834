import React,{useEffect, useRef, useState} from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'
import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'
import Select from 'react-select'
import { mapKeys } from 'lodash'
import { IFormSelectField } from "./FormSelectField.types"
import * as scss from './FormSelectField.module.scss'
import { Controller } from "react-hook-form";
export const FormSelectField: React.FC<IFormSelectField> = (props) => {

  const {
    id,
    primary: {
      field_label,
      field_name,
      field_placeholder,
      required,
      width
    },
    items
  } = props.data

  const refOptions = useRef<HTMLDivElement>(null)
  const [switchState, setSwitchState] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const { register, control } = props


  return (
    <div className={`${scss.root} ${width ? scss.root__full : scss.root__half}`}>
      <label className={`  ${scss.selectBtn} ${scss.selectBtn__visible}`} htmlFor="select">{field_label}</label>
      <Controller
        name={field_name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              placeholder={field_placeholder}
              options={items}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'transparent',
                  primary: 'transparent',
                },
              })}
              classNames={{
                control: (state) => state.menuIsOpen ? `${scss.selectBtn__control} ${scss.selectBtn__control__open}` : `${scss.selectBtn__control}`,
                valueContainer: () => scss.selectBtn__valueContainer,
                menu: () => scss.selectBtn__menu,
                option: () => scss.selectBtn__option,
                multiValueLabel: () => scss.selectBtn__multiValueLabel,
                multiValue: () => scss.selectBtn__multiValue,
                multiValueRemove: () => scss.selectBtn__multiValue__remove,
                singleValue: () => scss.selectBtn__singleValue
              }}
              onChange={(options) =>
                onChange(options.value)
              }
              onBlur={onBlur}
              value={items.filter((option) => value?.includes(option.value))}
              defaultValue={items.filter((option) =>
                value?.includes(option.value)
              )}
            />
          );
        }}
      />
    </div>
  )
}



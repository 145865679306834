import React from 'react'
import { Link } from 'gatsby'
import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'
import { v4 as uuidv4 } from 'uuid'
import { IDefaultRichText } from "./DefaultRichText.types"

export const DefaultRichText: React.FC<IDefaultRichText> = ({
  text
}) => {
  return (
    <RichText 
      render={text.richText}
      linkResolver={linkResolver}
      serializeHyperlink={SerializeHyperlink}
    />
  )
}

const SerializeHyperlink = (
  type,
  element,
  content,
  children,
  index) => {
    
    if (element.data.link_type === 'Document') {
      return (
        <Link
          key={uuidv4()}
          to={linkResolver(element.data)}
        >
          <span>
            <span>{children}</span>
          </span>
        </Link>
      )
    }
  
    if (element.data.link_type === 'Web') {
      return (
        <a 
          key={uuidv4()}
          href={element.data.url} 
          target={element.data.target} 
          rel='noopener'
        >
          <span>
            <span>{children}</span>
          </span>
        </a>
      )
    }
}



// extracted by mini-css-extract-plugin
export var card = "Mobile-module--card--4d157";
export var card__active = "Mobile-module--card__active--c30b4";
export var card__content = "Mobile-module--card__content--031f4";
export var card__content__job = "Mobile-module--card__content__job--95713";
export var card__content__name = "Mobile-module--card__content__name--59479";
export var card__video = "Mobile-module--card__video--053ad";
export var card__video__placeholder = "Mobile-module--card__video__placeholder--4225e";
export var card__video__playButton = "Mobile-module--card__video__playButton--1ecc4";
export var content = "Mobile-module--content--4a853";
export var content__details = "Mobile-module--content__details--acabc";
export var content__details__avatar = "Mobile-module--content__details__avatar--02503";
export var content__details__infos = "Mobile-module--content__details__infos--760d7";
export var content__details__infos__job = "Mobile-module--content__details__infos__job--7725e";
export var content__details__infos__name = "Mobile-module--content__details__infos__name--74d89";
export var content__quote = "Mobile-module--content__quote--8e6aa";
export var gatsbyImage = "Mobile-module--gatsbyImage--6af7e";
export var navigation = "Mobile-module--navigation--61000";
export var navigation__next = "Mobile-module--navigation__next--1ae10";
export var navigation__prev = "Mobile-module--navigation__prev--d84c1";
export var root = "Mobile-module--root--6f4a5";
export var videoWrapper = "Mobile-module--videoWrapper--170f7";
export var wrapper = "Mobile-module--wrapper--477e1";
import React, { useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IFullWidthImageContentQuote } from './FullWidthImageContentQuote.types'
import * as scss from './FullWidthImageContentQuote.module.scss'
import { useBreakpoints } from 'hooks/useMediaQuery'

const FullWidthImageContentQuote: React.FC<IFullWidthImageContentQuote> = ((props) => {
  const { id } = props
  const {
    primary: {
      content,
      quote,
      image
    }
  } = props.data

  if (
    !content.richText.length ||
    !image.localFile
  ) return

  const refRoot = useRef<HTMLDivElement>(null)
  const refImage = useRef<HTMLDivElement>(null)
  const { isLg } = useBreakpoints()

  const createScrollTrigger = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: 'top top',
        end: 'bottom center',
        scrub: 1,
      }
    })
    tl.to(refImage.current, { scale: 0.95, borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px' })
    ScrollTrigger.refresh()
  }

  useLayoutEffect(() => {
    
    isLg && createScrollTrigger()
    return () =>  isLg && ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [])

  return (
    <div className={`${scss.root}`} ref={refRoot}>
      <div className={scss.wrapper}>
        <div className={scss.image} ref={refImage}>
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div className={`${scss.content} g-width`}>
          <div className={scss.content__innerWrapper}>
            <div className={scss.content__wrapper}>
              <DefaultRichText text={content}/>
            </div>
            <div>
              <DefaultRichText text={quote}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export { FullWidthImageContentQuote }

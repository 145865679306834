import { graphql, useStaticQuery } from "gatsby"
import { find } from "lodash"
import { ImageDataLike } from "gatsby-plugin-image"

export type TGatsbyImage = {
  alt: string
  url?: string
  localFile?: ImageDataLike
}
interface IGetHeader  {
  lang: string
}

interface IGetHeaderQuery  {
  allPrismicHeader: {
    nodes: {
      lang: string
      data: {
        logo: {
          alt: string
          url: string
          localFile: TGatsbyImage
        }
        body: {
          id: string
          slice_type: string
        }[]
        body_overlay: {
          id: string
          slice_type: string
        }[]
      }
    }[]
  }
}

export const getHeader = ( { lang } : IGetHeader) => {
  const data = useStaticQuery<IGetHeaderQuery>(graphql`
    query {
      allPrismicHeader(sort: {fields: lang, order: ASC}) {
        nodes {
          lang
          data {
            logo {
              alt
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            body {
              ... on PrismicSliceType {
                id
                slice_label
                slice_type
              }
              ...FPrismicHeaderDataBodyNavItem
              ...FPrismicHeaderDataBodyNavLink
              ...FPrismicHeaderDataBodyNavItemLang
            }
            body_overlay {
              ... on PrismicSliceType {
                id
                slice_label
                slice_type
              }
              ...FPrismicHeaderDataBodyOverlayNavItem
              ...FPrismicHeaderDataBodyOverlayNavLink
            }
          }
        }
      }
    }
  `)
  return find(data.allPrismicHeader.nodes, (header) => header.lang === lang)
}

export const FPrismicHeaderDataBodyNavItem = graphql`
  fragment FPrismicHeaderDataBodyNavItem on PrismicHeaderDataBodyNavItem {
    primary {
      label {
        richText
        text
      }
    }
    items {
      link_label {
        richText
        text
      }
      link_url {
        uid
        lang
        type
      }
    }
  }
`
export const FPrismicHeaderDataBodyNavItemLang = graphql`
  fragment FPrismicHeaderDataBodyNavItemLang on PrismicHeaderDataBodyNavItemLang {
    items {
      link_label {
        richText
        text
      }
      lang
    }
  }
`
export const FPrismicHeaderDataBodyNavLink = graphql`
  fragment FPrismicHeaderDataBodyNavLink on PrismicHeaderDataBodyNavLink {
    primary {
      link_label {
        richText
        text
      }
      link_url {
        uid
        type
        target
        lang
      }
    }
  }
`
export const FPrismicHeaderDataBodyOverlayNavItem = graphql`
  fragment FPrismicHeaderDataBodyOverlayNavItem on PrismicHeaderDataBodyOverlayNavItem {
    primary {
      uid
      label {
        richText
        text
      }
    }
    items {
      link_label {
        richText
        text
      }
      link_url {
        uid
        lang
        type
      }
    }
  }
`
export const FPrismicHeaderDataBodyOverlayNavLink = graphql`
  fragment FPrismicHeaderDataBodyOverlayNavLink on PrismicHeaderDataBodyOverlayNavLink {
    primary {
      link_label {
        richText
        text
      }
      link_url {
        uid
        type
        lang
      }
    }
  }
`
export const site = {
  title: "AC Consulting",
  titleDefault: "AC Consulting",
  titleAlt: "AC Consulting",
  url: "https://www.ac-consulting.com/",
  dataDomain: "ac-consulting.com",
  description: "AC Consulting",
  image: "src/assets/images/favicon.png",
  background_color: '#FFFFFF',
  theme_color: '#155AC3',
  twitter: "@",
}
import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'

import { useBreakpoints } from 'hooks/useMediaQuery'
import linkResolver from 'utils/linkResolver'

import { Accordion } from 'components/common/Accordion/Accordion'

import { INavItem } from '../NavItem.types'
import * as scss from "./NavItem.default.module.scss"
import { RichText } from 'prismic-reactjs'

const NavItemDefault: React.FC<INavItem> = ((props) => {

  const {
    data: {
      primary: {
        label
      },
      items
    }
  } = props

  const [toggle, setToggle] = useState(false)
  const [tl] = useState(() => gsap.timeline({ paused: true }))
  const refIsInit = useRef<boolean>(false)
  const refRoot = useRef<HTMLDivElement>(null)
  const refArrow = useRef<HTMLSpanElement>(null)
  const { isLg } = useBreakpoints()

  const handleClick = () => setToggle((toggle: boolean) => !toggle)
  const handleMouseEnter = () => {
    if (!isLg) return
    gsap.to("html", {"--blur": '5px'})

  }
  const handleMosueLeave = () => {
    if (!isLg) return
    gsap.to("html", {"--blur": '0px'})
  }

  useEffect(() => {
    if(!refArrow.current) return

    tl.to(refArrow.current, { rotate: 0}, 0)

  }, [refArrow])

  useEffect(() => {
    if (!refIsInit.current || isLg) return
    if (tl.paused()) tl.play()
    else tl.reversed(!toggle)
  }, [toggle])

  useEffect(() => { refIsInit.current = true } ,[])

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMosueLeave}
      className={scss.root}
      ref={refRoot}
    >
      <div
        className={`${scss.title} link link--underline`}
        onClick={handleClick}
      >
        <RichText render={label.richText} />
        <span className={scss.arrow} ref={refArrow} />
      </div>
      <Accordion className={scss.links} timeline={tl}>
        <div className={scss.links__wrapper}>
          { items.map(( { link_label, link_url }, index ) => (
            <Link
              className={scss.links__link}
              key={`overlayMobile--${index}`}
              to={linkResolver(link_url)}
              onClick={handleClick}
            >
              <RichText render={link_label.richText} />
            </Link>
          ))}
        </div>
      </Accordion>
    </div>
  )
})

export {NavItemDefault}
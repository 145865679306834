import React from "react"
import { graphql } from "gatsby"

import { SEO } from 'components/SEO/Seo'
import SliceZone from "components/common/sliceZone/SliceZone"

import { IPageTemplate } from "./PageTemplate.types"
import * as scss from './PageTemplate.module.scss'

const PageTemplate: React.FC<IPageTemplate> = ({ data }) => {
  const { page } = data
  return (
    <div className={scss.root}>
      { data.page && data.page.data.body && <SliceZone data={page.data.body}/> }
    </div>
  )
}

export default PageTemplate

export const Head = ({ 
  data: {
    page: {
      data: {
        meta_title = 'default meta title',
        meta_description = 'default meta description'
      }
    }
  }
}) => (
  <SEO
    title={meta_title}
    description={meta_description}
  />
)

export const query = graphql`
  query pageTemplate($uid: String!, $lang: String!) {
    page : prismicPageTemplate(uid: {eq: $uid}, lang: {eq: $lang}) {
      data {
        meta_description
        meta_title
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...FPrismicPageTemplateDataBodyHero
          ...FPrismicPageTemplateDataBodyHeroVariation1
          ...FPrismicPageTemplateDataBodyHorizontalSlideshow
          ...FPrismicPageTemplateDataBodyCircleLogosContent
          ...FPrismicPageTemplateDataBodyRowContentIcon
          ...FPrismicPageTemplateDataBodyContentBackgroundImageMap
          ...FPrismicPageTemplateDataBodyTestimonialsRowVideos           
          ...FPrismicPageTemplateDataBodyTestimonialsCardsSlider
          ...FPrismicPageTemplateDataBodyCtasBackgroundImage
          ...FPrismicPageTemplateDataBodyVerticalScrollingContentImage
          ...FPrismicPageTemplateDataBodyImageContent
          ...FPrismicPageTemplateDataBodyVerticalScrollingSlider
          ...FPrismicPageTemplateDataBodyGridIconContentWithCta
          ...FPrismicPageTemplateDataBodyNumberContentColumn
          ...FPrismicPageTemplateDataBodyContentVideosSlider
          ...FPrismicPageTemplateDataBodyColumnIconContent
          ...FPrismicPageTemplateDataBodyFullWidthImageContentAndQuote
          ...FPrismicPageTemplateDataBodyContentSquarePictures
          ...FPrismicPageTemplateDataBodyContentForm
          ...FPrismicPageTemplateDataBodyContent
          ...FPrismicPageTemplateDataBodyRssFeedList
        }
      }
    }
  }
`
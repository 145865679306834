import React,{Fragment} from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'

import { IFormTextArea } from "./FormTextArea.types"
import * as scss from './FormTextArea.module.scss'

export const FormTextArea: React.FC<IFormTextArea> = (props) => {

  const {
    id,
    primary: {
      field_label,
      field_name,
      field_placeholder,
      field_type,
      required,
      width
    },
  } = props.data

  const { register } = props

  // console.log(
  //   field_label,
  //   field_name,
  //   field_placeholder,
  //   field_type
  // )
  // console.log(width)

  const requiredField = required ? {
    required: 'error required',
  } : {}

  const requiredFieldEmail = required ? {
    required: 'error required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    }
  } : {}

  return (
    <label htmlFor={field_type} className={`${scss.root} ${width ? scss.root__full : scss.root__half}`}>
      <span className={scss.label}>{field_label} {required && <span className={scss.start}>*</span>}</span>
      <textarea
        // className={errors[field_name] ? 'error' : ''}
        className={scss.input}
        type={field_type}
        name={field_name}
        placeholder={field_placeholder}
        {...register(field_name, field_name === 'email' && required ? requiredFieldEmail : required ? requiredField : false )}
      />
  </label>
  )
}



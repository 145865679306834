import React,{useEffect, useRef, useState} from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'
import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'
import Select from 'react-select'
import { mapKeys } from 'lodash'
import { IFormSwitchField } from "./FormSwitchField.types"
import { Controller } from "react-hook-form";
import * as scss from './FormSwitchField.module.scss'

export const FormSwitchField: React.FC<IFormSwitchField> = (props) => {

  const {
    id,
    primary: {
      radio_field_label,
      radio_field_name,
      radio_field_value_false,
      radio_field_value_true,
      select_field_label,
      required,
      width
    },
    items
  } = props.data

  const refOptions = useRef<HTMLDivElement>(null)
  const [switchState, setSwitchState] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const { register, control } = props


  const handleSwitchClick = () => {
    setSwitchState((toggle: boolean) => !toggle)
  }

  return (
    <div className={`${scss.root} ${width ? scss.root__full : scss.root__half}`}>
      <span className={`${scss.label} ${scss.mainLabel}`}>{radio_field_label}{required && <span className={scss.start}>*</span>}</span>

      <div className={`${scss.switchBtn} ${switchState && scss.switchBtn__active}`} onClick={handleSwitchClick}>
        <span className={scss.switchBtn__text}>{radio_field_value_true}</span>
        <span className={scss.switchBtn__text}>{radio_field_value_false}</span>
      </div>

      <div className={`${scss.selectBtn} ${!switchState ? scss.selectBtn__visible : scss.selectBtn__hide}`}>
        <div className={scss.selectBtn__current}>
        <Controller
        name={radio_field_name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              placeholder={select_field_label}
              options={items}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'transparent',
                  primary: 'transparent',
                },
              })}
              isMulti
              classNames={{
                control: (state) => state.menuIsOpen ? `${scss.selectBtn__control} ${scss.selectBtn__control__open}` : `${scss.selectBtn__control}`,
                valueContainer: () => scss.selectBtn__valueContainer,
                menu: () => scss.selectBtn__menu,
                option: () => scss.selectBtn__option,
                multiValueLabel: () => scss.selectBtn__multiValueLabel,
                multiValue: () => scss.selectBtn__multiValue,
                multiValueRemove: () => scss.selectBtn__multiValue__remove,
                singleValue: () => scss.selectBtn__singleValue
              }}
              onChange={(options) =>
                onChange(options?.map((option) => option.value))
              }
              onBlur={onBlur}
              value={items.filter((option) => value?.includes(option.value))}
              defaultValue={items.filter((option) =>
                value?.includes(option.value)
              )}
            />
          );
        }}
      />
        </div>
      </div>
    </div>

  )
}



{/* <Select
classNames={{
  control: (state) => state.menuIsOpen ? `${scss.selectBtn__control} ${scss.selectBtn__control__open}` : `${scss.selectBtn__control}`,
  valueContainer: () => scss.selectBtn__valueContainer,
  menu: () => scss.selectBtn__menu,
  option: () => scss.selectBtn__option,
  multiValueLabel: () => scss.selectBtn__multiValueLabel,
  multiValue: () => scss.selectBtn__multiValue,
  multiValueRemove: () => scss.selectBtn__multiValue__remove
}}
defaultValue={'hello'}
placeholder={select_field_label}
isMulti
name="colors"
options={items}
className="basic-multi-select"
classNamePrefix="select"
// onChange={(choice) => setSelectedOptions(current => [...current, choice])}
onChange={(choice) => handleChange(choice)}
theme={(theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: 'transparent',
    primary: 'transparent',
  },
})}
/> */}
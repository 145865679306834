interface Resize {
  callbacks: {
    name: string
    callback: Function
  }[]
}

class Resize {

  constructor() {
    this.callbacks = []
    this.bind()
  }

  init() {
    this.addEventsListeners()
    this.setVh()
  }

  setVh() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  subscribe(name : string, callback : Function) {
    this.callbacks.push({
      name: name,
      callback: callback
    })
  }
  
  unsubscribe(name : string) {
    this.callbacks.forEach((item, i) => {
      if (item.name == name) this.callbacks.splice(i, i + 1)
    })
  }

  onResize() {
    this.callbacks.forEach(item => item.callback() )
    this.setVh()
  }

  addEventsListeners() {
    if (!window)return
    window.addEventListener('resize', this.onResize)
  }

  bind() {
    this.onResize = this.onResize.bind(this)
  }
}

const _instance = new Resize()
export default _instance
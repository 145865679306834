import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import SCROLL from 'utils/scroll'

import { useBreakpoints } from 'hooks/useMediaQuery'
import { useStoreOverlayMenu } from 'state/Global'
import { getHeader } from 'hooks/staticQuery/getHeader'
import RESIZE from 'utils/eventResize'

import SliceZone from 'components/common/sliceZone/SliceZone'

import { Overlay, TOverlayHandle } from './overlay/'
import * as scss from './Header.module.scss'

gsap.registerPlugin(ScrollTrigger)

const rotationAnimation = {
  ease: "elastic.inOut(.80, .75)",
  rotate: "90Ddeg",
  y: '50%',
  duration: 1.25
}

export const Header = ()  => {
  const {
    overlayOpen,
    SET_OVERLAY,
    currentLang
  } = useStoreOverlayMenu()
  const { 
    data: {
      body,
      body_overlay,
      logo,
    },
  } = getHeader({ lang: currentLang })

  const refIsInit = useRef<boolean>(false)
  const refLinksWrapper = useRef<HTMLDivElement>(null)
  const refLinksTitleMenu = useRef<HTMLDivElement>(null)
  const refLinksTitleClose = useRef<HTMLDivElement>(null)
  const refRoot = useRef<HTMLDivElement>(null)
  const refOverlay = useRef<TOverlayHandle>(null)
  const refHeader = useRef<HTMLDivElement>(null)
  const refDot = useRef<HTMLDivElement>(null)
  const refLogo = useRef<HTMLDivElement>(null)
  const refScrollTrigger = useRef(null)

  const [toggle, setToggle] = useState(false)
  const [tl] = useState(() => gsap.timeline({ paused: true, defaults: { duration: .6 } }))
  const [tlScroll] = useState(() => gsap.timeline({ paused: true, defaults: { duration: .3 } }))
  const { isLg } = useBreakpoints()


  const setHeaderMobilePadding = () => {
    document.documentElement.style.setProperty('--g-padding', `-${refHeader.current.getBoundingClientRect().height}px`)
  }
  const handleClick = () => {
    SET_OVERLAY(overlayOpen ? false : true)
  }

  const createScrollTrigger = () => {
    refScrollTrigger.current = ScrollTrigger.create({
      id: 'discordScroll',
      trigger: refHeader.current,
      start: '5px top',
      scrub: true,
      onEnter: () => { onEnterScrollTrigger(true) },
      onEnterBack: () => { onEnterScrollTrigger(false) },
      // markers: true
    })
  }

  const onEnterScrollTrigger = (direction) => {
    if (tlScroll.paused()) tlScroll.play()
    else tlScroll.timeScale(1.5).reversed(!direction)
  }

  useEffect(() => {
    setToggle(overlayOpen)
  }, [overlayOpen])

  useEffect(() => {
    refOverlay.current && tl.add(refOverlay.current.animateIn(), 0)
    refDot.current && tl.to(refDot.current, { backgroundColor: '#FFFFFF' }, .5)
    refLinksTitleMenu.current && tl.to(refLinksTitleMenu.current, { autoAlpha: 0 }, .5)
    refLinksTitleClose.current && tl.to(refLinksTitleClose.current, { autoAlpha: 1 }, .6)

    if (isLg) {
      if (refLinksWrapper.current) {
        tl.to(refLinksWrapper.current, {
          ...rotationAnimation,
          autoAlpha: 0,
        }, 0)
        tlScroll.to(refLinksWrapper.current, {
          ...rotationAnimation,
          autoAlpha: 0,
        }, 0)
      }
      if (refLinksTitleMenu.current) {
        tl.to(refLinksTitleMenu.current, {
          ...rotationAnimation,
          autoAlpha: 0,
        }, 0)
        tlScroll.to(refLinksTitleMenu.current, {
          ...rotationAnimation,
          autoAlpha: 1,
        }, 0)
      }
      refLinksTitleClose.current && tl.to(refLinksTitleClose.current, {
        ...rotationAnimation,
        autoAlpha: 1,
      }, 0)
      if (refLogo.current) {
        tl.to(refLogo.current, {
          autoAlpha: 0,
        }, 0)
        tlScroll.to(refLogo.current, {
          autoAlpha: 0,
        }, 0)
      }
    }

  }, [refLinksWrapper, refLinksTitleMenu, refLinksTitleClose, refDot])


  useEffect(() => {

    if (!refIsInit.current) return
    if (tl.paused()) tl.play()
    else tl.reversed(!toggle)

    if (toggle) {
      SCROLL.lenis.stop()
      ScrollTrigger.getById('discordScroll').disable()
    }
    if (!toggle) {
      SCROLL.lenis.start()
      ScrollTrigger.getById('discordScroll').enable()
    }

  }, [toggle])

  useEffect(() => {
    createScrollTrigger()
    refIsInit.current = true
  } ,[])

  useEffect(() => {
    setHeaderMobilePadding()
    RESIZE.subscribe('set header mobile padding', setHeaderMobilePadding)
    return () => RESIZE.unsubscribe('set header mobile padding')
  }, [])
  return (
    <div className={scss.root} ref={refRoot}>
      <div className={`${scss.header} g-width`} ref={refHeader}>
        <div className={scss.header__wrapper}>
          <div ref={refLogo}>
            <Link
              onClick={() => SET_OVERLAY(false)}
              to={currentLang !== 'fr-fr' ? `/${currentLang}` : `/`}
              className={scss.logo}>
                {/* <StaticImage
                  width={196}
                  height={45}
                  src='../../../assets/images/logo-ac-horizontal.svg'
                  alt={logo.alt ? logo.alt : 'AC Consulting'}
                /> */}
              <GatsbyImage
                className={scss.logo}
                image={getImage(logo.localFile)}
                alt={logo.alt ? logo.alt : 'AC Consulting'}
              />
            </Link>
          </div>

          <div className={scss.links}>
            <div className={scss.links__wrapper} ref={refLinksWrapper}>
              <SliceZone data={body}/>
            </div>
            <div
              className={scss.links__dot}
              onClick={handleClick}
              ref={refDot}
            />
            <div
              className={`${scss.links__title} ${scss.links__title__menu}`}
              ref={refLinksTitleMenu}
            >
              MENU
            </div>
            <div
              className={`${scss.links__title} ${scss.links__title__close}`}
              ref={refLinksTitleClose}
            >
              FERMER
            </div>
          </div>
        </div>


      </div>

      <Overlay
        body={body_overlay}
        toggle={toggle}
        timeline={tl}
        ref={refOverlay}
      />

    </div>
  )
}

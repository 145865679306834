import React from 'react'
import { INavLink } from './NavLink.types'
import { NavLinkOverlay } from './overlay/NavLink.overlay'
import { NavLinkDefault } from './default/NavLink.default'

const NavLink: React.FC<INavLink> = ((props) => {
  const { variation } = props

  switch (variation) {
    case 'overlay':
      return <NavLinkOverlay data={props.data}/>
      break

    case 'footer':
      return <div></div>
      break

    default:
      return <NavLinkDefault data={props.data}/>
      break
  }

})

export { NavLink }

import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import { IGridIconContentWithCta } from './GridIconContentWithCta.types'
import * as scss from './GridIconContentWithCta.module.scss'
import { navigate } from 'gatsby'
import linkResolver from 'utils/linkResolver'

const GridIconContentWithCta: React.FC<IGridIconContentWithCta> = ((props) => {
  const {
    primary: {
      content,
      link_label,
      link_url,
      columns
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return

  const handleClick = () => {
    navigate(linkResolver(link_url))
  }

  return (
    <div className={scss.root}>
      <div className={`g-width`}>

        <div className={scss.content}>
          <DefaultRichText text={content}/>
        </div>

        <div className={`${scss.cards} ${columns && scss.cards__four}`} >
          { items.map(({ content, image }, index) => <Card content={content} image={image} key={`gridContentWithCta--${props.id}--${index}`}/>) }
          <div className={scss.cta} onClick={handleClick}>
            <DefaultLink link_label={link_label} link_url={link_url}/>
          </div>
        </div>

      </div>
    </div>
  )
})

export { GridIconContentWithCta }

const Card = ({content, image}) => {
  return (
    <div className={scss.card}>
      <div>
        <div className={scss.card__image}>
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div><DefaultRichText text={content}/></div>
      </div>
    </div>
  )
}

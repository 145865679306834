import React, { Fragment, useEffect } from 'react'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useBreakpoints } from 'hooks/useMediaQuery'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import backgroundShape from 'assets/images/background-shape-noir-light.svg'
import { IImageContent } from './ImageContent.types'
import * as scss from './ImageContent.module.scss'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'

const ImageContent: React.FC<IImageContent> = ((props) => {
  const {
    id,
    primary: {
      content,
      image
    }
  } = props.data

  return (
    <div className={scss.root}>
      <div className={`${scss.wrapper} g-width`}>
        <div>
          <GatsbyImage
            image={getImage(image.localFile)}
            alt={image.alt ? image.alt : 'AC - Consulting'}
          />
        </div>
        <div className={`${scss.content} `}>
          <DefaultRichText text={content}/>
        </div>
      </div>
    </div>
  )
})

export { ImageContent }


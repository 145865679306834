import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { getFooter } from 'hooks/staticQuery/getFooter'
import SliceZone from 'components/common/sliceZone/SliceZone'
import { useStoreOverlayMenu } from 'state/Global'
import SCROLL from 'utils/scroll'

import * as scss from './Footer.module.scss'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'

export const Footer = ()  => {
  const {
    currentLang
  } = useStoreOverlayMenu()
  const { data: { body, logo, bottom_text}} = getFooter({ lang: currentLang})


  const handleClick = () => {
    SCROLL.lenis.scrollTo(0)
  }

  return (
    <div className={`${scss.root}`}>

      <div className={`${scss.wrapper} g-margin g-width`}>

        <div className={scss.top}>
          <Link to="/" className={scss.logo}>
            <GatsbyImage
              image={getImage(logo.localFile)}
              alt={logo.alt ? logo.alt : 'AC - Consulting'}
            />
          </Link>
        </div>

        <div className={scss.links}>
            <SliceZone data={body}/>
        </div>

        <div className={scss.bottom}>
          <DefaultRichText text={bottom_text}/>
        </div>

      </div>

      <div className={scss.scrollTop} onClick={handleClick}></div>

    </div>
  )
}

import React,{Fragment} from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'

import { IDefaultLink } from "./DefaultLink.types"

export const DefaultLink: React.FC<IDefaultLink> = ({
  link_url,
  link_label,
  className
}) => {

  let label = <Fragment>Default label</Fragment>

  if (link_label.text) label = <Fragment>{link_label.text}</Fragment>
  if (link_label.richText && link_label.richText.length > 0) label = <RichText render={link_label.richText} />

  if (link_url.url) {
    return (
      <a
        className={className}
        href={link_url.url}
        target={link_url.target}
      >
        <span>
          <span>{label}</span>
        </span>
      </a>
    )
  }
  
  else if (link_url.uid && link_url.type && link_url.lang) {
    return (
      <Link
        className={className} 
        to={linkResolver(link_url)}
      >
        <span>
          <span>{label}</span>
        </span>
      </Link>
    )
  }

  else {
    return (
      <div></div>
    )
  }

}



import React,{Fragment} from 'react'
import { Link } from 'gatsby'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'

import { IFormRadioField } from "./FormRadioField.types"
import * as scss from './FormRadioField.module.scss'

export const FormRadioField: React.FC<IFormRadioField> = (props) => {

  const {
    id,
    primary: {
      field_label,
      field_name,
      field_placeholder,
      field_type,
      required,
      width
    },
    items
  } = props.data

  const { register } = props

  const requiredField = required ? {
    required: 'error required',
  } : {}

  const requiredFieldEmail = required ? {
    required: 'error required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    }
  } : {}

  return (
    <div className={`${scss.root} ${width ? scss.root__full : scss.root__half}`}>
      <span className={`${scss.label} ${scss.mainLabel}`}>{field_label}</span>
      {
        items.map(({ option_label, option_value }, index) => {
          let props = index === 0 ? { checked: 'checked' } : null

          return (
            <label
              key={`formRadioField--${id}--input--${index}`}
              className={scss.field} htmlFor={field_type}
            >
              <span className={scss.label}>{option_label}</span>
              <input
                
                type="radio"
                defaultChecked={index === 0 ? true : false}
                // className={errors[field_name] ? 'error' : ''}
                className={scss.input}
                name={field_name}
                value={option_value}
                placeholder={field_placeholder}
                {...register(field_name, field_name === 'email' && required ? requiredFieldEmail : required ? requiredField : false )}
              />
              <span className={scss.checkmark}></span>
            </label>
          )
        })
      }
    </div>

  )
}



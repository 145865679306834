import React, { useEffect, useLayoutEffect, useRef  } from "react"
import gsap from 'gsap'
import { SkipNavContent, SkipNavLink } from "components/common/skipNav/SkipNav"
import { useStoreOverlayMenu } from 'state/Global'
import SCROLL from "utils/scroll"
import RESIZE from 'utils/eventResize'
import RAF from 'utils/raf'
import { Header } from "./header/Header"
import { Footer } from "./footer/Footer"
import * as scss from "./Layout.module.scss"
import { ILayout } from "./Layout.types"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Layout: React.FC<ILayout> = ({ children, location, pageContext  }) => {

  const { currentLang, SET_CURRENT_LANG, SET_ALT_LANG } = useStoreOverlayMenu()

  gsap.config({ force3D: true })
  const refOverlay = useRef<HTMLSpanElement>(null)

  
  useLayoutEffect(() => {
    gsap.to(refOverlay.current, {opacity: 0, duration: 1, ease: "power2.out"})
  }, [])

  useEffect(() => {
    RAF.init()
    RESIZE.init()
  }, [])

  useLayoutEffect(() => {
    SCROLL.init()
    gsap.to(refOverlay.current, {
      delay: 2.5,
      opacity: 0,
      duration: 2.5,
      ease: "power2.out"
    })
  }, [])

  useEffect(() => {
    SCROLL.lenis.scroll = 0
    window.scrollTo(0,0)
    SCROLL.lenis.scrollTo(0)
    SET_CURRENT_LANG(pageContext.lang)
    SET_ALT_LANG(pageContext.altLang)
    console.log(pageContext.altLang)
  }, [location])
  
  return (
    <main>
      <SkipNavLink/>
      <div className={scss.root}>
        <span className={scss.overlay_loader} ref={refOverlay} />
        <Header/>
        <div className={scss.wrapper}>
          { children }
        </div>
        <Footer/>
      </div>
    </main>
  )
}

export default Layout
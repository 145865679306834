// extracted by mini-css-extract-plugin
export var checkmark = "FormSwitchField-module--checkmark--87b87";
export var field = "FormSwitchField-module--field--48696";
export var label = "FormSwitchField-module--label--51676";
export var mainLabel = "FormSwitchField-module--mainLabel--e9f89";
export var root = "FormSwitchField-module--root--c4e79";
export var root__half = "FormSwitchField-module--root__half--23a00";
export var selectBtn = "FormSwitchField-module--selectBtn--210ef";
export var selectBtn__control = "FormSwitchField-module--selectBtn__control--51a1d";
export var selectBtn__control__open = "FormSwitchField-module--selectBtn__control__open--400e5";
export var selectBtn__hide = "FormSwitchField-module--selectBtn__hide--b5948";
export var selectBtn__menu = "FormSwitchField-module--selectBtn__menu--59944";
export var selectBtn__multiValue = "FormSwitchField-module--selectBtn__multiValue--2c691";
export var selectBtn__multiValueLabel = "FormSwitchField-module--selectBtn__multiValueLabel--20358";
export var selectBtn__multiValue__remove = "FormSwitchField-module--selectBtn__multiValue__remove--65727";
export var selectBtn__option = "FormSwitchField-module--selectBtn__option--5c320";
export var selectBtn__valueContainer = "FormSwitchField-module--selectBtn__valueContainer--75209";
export var selectBtn__visible = "FormSwitchField-module--selectBtn__visible--0f342";
export var start = "FormSwitchField-module--start--68628";
export var switchBtn = "FormSwitchField-module--switchBtn--5f7be";
export var switchBtn__active = "FormSwitchField-module--switchBtn__active--c74a8";
export var switchBtn__text = "FormSwitchField-module--switchBtn__text--244a7";
import React, { useRef } from 'react'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import { INavLink } from '../NavLink.types'
import * as scss from "./NavLink.default.module.scss"

const NavLinkDefault: React.FC<INavLink> = (({
  data: {
    primary: {
      link_label,
      link_url
    },
  }
}) => {
  const refTitle = useRef<HTMLDivElement>(null)
  return (
    <div
      ref={refTitle}
      className={`${scss.root} link link--underline`}
    >
      <DefaultLink
        link_url={link_url}
        link_label={link_label}
      />
    </div>
  )
})

export { NavLinkDefault }
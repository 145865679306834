// extracted by mini-css-extract-plugin
export var active = "VerticalScrollingSlider-module--active--dcdf3";
export var content = "VerticalScrollingSlider-module--content--aff54";
export var navigation = "VerticalScrollingSlider-module--navigation--8e279";
export var navigation__dot = "VerticalScrollingSlider-module--navigation__dot--b9950";
export var navigation__dots = "VerticalScrollingSlider-module--navigation__dots--471d4";
export var navigation__wrapper = "VerticalScrollingSlider-module--navigation__wrapper--e950a";
export var root = "VerticalScrollingSlider-module--root--98849";
export var verticalContent = "VerticalScrollingSlider-module--verticalContent--54460";
export var verticalContent__content = "VerticalScrollingSlider-module--verticalContent__content--05c43";
export var verticalContent__content__index = "VerticalScrollingSlider-module--verticalContent__content__index--ab4ee";
export var verticalContent__content__index__wrapper = "VerticalScrollingSlider-module--verticalContent__content__index__wrapper--f2c66";
export var verticalContent__image = "VerticalScrollingSlider-module--verticalContent__image--7e704";
export var verticalContent__item = "VerticalScrollingSlider-module--verticalContent__item--ddacb";
export var verticalContent__wrapper = "VerticalScrollingSlider-module--verticalContent__wrapper--0464f";
import React, { useRef, useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import gsap from 'gsap'

import { useBreakpoints } from 'hooks/useMediaQuery'
import { useStoreOverlayMenu } from 'state/Global'
import linkResolver from 'utils/linkResolver'

import { Accordion } from 'components/common/Accordion/Accordion'

import { INavItem } from './NavItem.types'
import * as scss from "./NavItem.default.module.scss"
import { RichText } from 'prismic-reactjs'

const NavItemLang: React.FC<INavItem> = ((props) => {

  const {
    data: {
      items
    }
  } = props

  const [toggle, setToggle] = useState(false)
  const [tl] = useState(() => gsap.timeline({ paused: true }))
  const { currentLang, altLang } = useStoreOverlayMenu()
  const refIsInit = useRef<boolean>(false)
  const refRoot = useRef<HTMLDivElement>(null)
  const refArrow = useRef<HTMLSpanElement>(null)
  const { isLg } = useBreakpoints()

  const handleClick = ({ url }) => {
    console.log(url)
    if (url) navigate(url)
    setToggle((toggle: boolean) => !toggle)
    handleMosueLeave()
  }

  const handleMouseEnter = () => {
    if (!isLg) return
    gsap.to("html", {"--blur": '5px'})

  }
  const handleMosueLeave = () => {
    if (!isLg) return
    gsap.to("html", {"--blur": '0px'})
  }

  useEffect(() => {
    if(!refArrow.current) return

    tl.to(refArrow.current, { rotate: 0}, 0)

  }, [refArrow])

  useEffect(() => {
    if (!refIsInit.current || isLg) return
    if (tl.paused()) tl.play()
    else tl.reversed(!toggle)
  }, [toggle])

  useEffect(() => { refIsInit.current = true } ,[])

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMosueLeave}
      className={scss.root}
      ref={refRoot}
    >
      <div
        className={`${scss.title} link link--underline`}
        onClick={handleClick}
      >
        <div>{currentLang.slice(0, 2).toUpperCase()}</div>
        <span className={scss.arrow} ref={refArrow} />
      </div>
      <Accordion className={scss.links} timeline={tl}>
        <div className={scss.links__wrapper}>
          { items.map(( { link_label, lang }, index ) => {
            if (lang && lang.includes(currentLang)) return null
            let target = lang !== 'fr-fr' ? `/${lang}` : `/`
            if (altLang && altLang.length > 0) {
              target = linkResolver(altLang.find((item) => item.lang === lang))
              // console.log(linkResolver(altLang.find((item) => item.lang === lang)))
              // console.log(target)
            }
            return (
              <div
                className={scss.links__link}
                key={`overlayMobile--${index}`}
                // to={lang !== 'fr-fr' ? `/${lang}` : `/`}
                onClick={() => handleClick({url: target})}
              >
                <RichText render={link_label.richText} />
              </div>
            )
          }
          )}
        </div>
      </Accordion>
    </div>
  )
})

export {NavItemLang}
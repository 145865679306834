import React from 'react'
import { useBreakpoints } from 'hooks/useMediaQuery'
import { Desktop } from './Desktop/Desktop'
import { Mobile } from './Mobile/Mobile'
import { ITestimonialsRowVideos } from './TestimonialsRowVideos.types'

const TestimonialsRowVideos: React.FC<ITestimonialsRowVideos> = ((props) => {
  const {
    id,
    items
  } = props.data
  const { isLg } = useBreakpoints()

  if (isLg) return <Desktop items={items} id={id}/> 
  else return <Mobile items={items} id={id}/>

})

export { TestimonialsRowVideos }

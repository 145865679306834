// extracted by mini-css-extract-plugin
export var background = "HorizontalSlideShow-module--background--6c136";
export var background__image = "HorizontalSlideShow-module--background__image--9b2ea";
export var background__imageWrapper = "HorizontalSlideShow-module--background__imageWrapper--88366";
export var content = "HorizontalSlideShow-module--content--f62aa";
export var root = "HorizontalSlideShow-module--root--be81f";
export var slide = "HorizontalSlideShow-module--slide--fbb5c";
export var slide__image = "HorizontalSlideShow-module--slide__image--e2561";
export var slide__imageColor = "HorizontalSlideShow-module--slide__imageColor--e23fc";
export var slideshow = "HorizontalSlideShow-module--slideshow--20032";
export var slideshow__top = "HorizontalSlideShow-module--slideshow__top--7753c";
export var wrapper = "HorizontalSlideShow-module--wrapper--f8019";
export var wrapper__center = "HorizontalSlideShow-module--wrapper__center--a08f6";
export var wrapper__inner = "HorizontalSlideShow-module--wrapper__inner--f86bf";
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import axios from 'axios'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import gsap from 'gsap'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import * as scss from './RssFeedList.module.scss'
import { IRssFeedList, ICard } from './RssFeedList.types'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const RssFeedList: React.FC<IRssFeedList> = ((props) => {
  const {
    id,
    primary: {
      content,
    },
    items
  } = props.data

  const [posts, setPosts] = useState([])
  const [sortPosts, setSortPost] = useState([])

  const request = (rss_feed_url) => {
    const corsUrl = "https://api.rss2json.com/v1/api.json?rss_url=";
    let url = `${corsUrl}${rss_feed_url}`

    axios({
      method: 'get',
      url: url,
    })
      .catch(function (error) {
        console.log('fdsfsd')
      })
      .then(function (response) {
        // setPosts(response.data.items)
        let posts = response.data?.items
        console.log(posts)
        posts && setPosts(current => [...current, ...posts])
      })
  }

  // setRawData(current => [...current, ...add])

  useEffect(() => {
    items.length > 0 && items.forEach(({ rss_feed_url }) => request(rss_feed_url.url))
  }, [])

  useEffect(() => {
    if (!posts) return
    // let test  = posts.sort((a, b) => b.pubDate - a.pubDate)
    let sorted = posts.sort(function(a, b) {
      var d1 = new Date(a.pubDate)
      var d2 = new Date(b.pubDate)
      return d2.getTime() - d1.getTime()
    })
    setSortPost(sorted)
  }, [posts])


  return (
    <div className={`${scss.root} g-width`} >
      <div className={scss.content}>
        <DefaultRichText text={content}/>
      </div>
      <div className={scss.wrapper}>
        {
          sortPosts && sortPosts.map((post, index) => <Card key={`$RssFeed-${id}--${index}`} post={post}/>)
        }
      </div>
    </div>
  )
})

export { RssFeedList }

const Card = ({ post }) => {

  return (
    <a href={post.link} target="__blank">
      <div className={scss.card}>
        <div className={scss.card__image}>
          {post.thumbnail && <img src={post.thumbnail}/>}
        </div>
        <div className={scss.card__content}>
          <h5>{post.title || <Skeleton />}</h5>
          <div dangerouslySetInnerHTML={{__html: `${post.description.substring(0,80)}...` }}/>
          <div className={scss.card__content__author}>{post.author}</div>
        </div>
      </div>
    </a>
  )
}

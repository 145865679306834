import React, { useRef } from 'react'
import { useBreakpoints } from 'hooks/useMediaQuery'
import { v4 as uuidv4 } from 'uuid'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import Carousel from "components/lib/Carousel/Carousel"
import * as scss from './TestimonialsCardsSlider.module.scss'
import { ITestimonialsCardsSlider } from './TestimonialsCardsSlider.types'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const TestimonialsCardsSlider: React.FC<ITestimonialsCardsSlider> = ((props) => {
  const {
    id,
    primary,
    items
  } = props.data

  if (
      !primary.content.richText.length ||
      !items.length
    ) return

  const refSlider = useRef<any>()
  const prev = () => { refSlider && refSlider.current && refSlider.current.slickPrev() }
  const next = () => { refSlider && refSlider.current && refSlider.current.slickNext() }

  const navigation = (
      <div className={scss.navigation}>
        <span
          onClick={prev}
          className={scss.navigation__prev}
        />
        <span
          onClick={next}
          className={scss.navigation__next}
        />
      </div>
    )


  const { isLg } = useBreakpoints()
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.7,
    slidesToScroll: 1,
    centerMode: true,
    // className: "center",
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.7,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className={scss.root}>
      <div className={`${scss.top} g-width `}>
        <div className={scss.title}><DefaultRichText text={primary.content}/></div>
        { isLg && (navigation) }
      </div>
      <div>
        <Slider {...settings} ref={refSlider}>
          { items.map(({ testimonial }, index) => 
              <Card
                key={uuidv4()}
                index={index}
                testimonial={testimonial}
              />
            )
          } 
        </Slider>
      </div>
      <div className={scss.bottom}>
        {navigation}
      </div>
    </div>
  )

})

export { TestimonialsCardsSlider }

const Card = ({ testimonial, index }) => {
  return (
    <div className={scss.card}>
      {/* <span className={scss.card__background}/> */}
      <div className={scss.card__wrapper}>
        <span className={scss.card__quoteSvg}/>
        <div>
          <div className={scss.card__quote}>
            <span>
              <DefaultRichText text={testimonial.document.data.quote_2}/>
            </span>
          </div>
          <div className={scss.card__details}>
            <div className={scss.card__details__avatar}>
              <GatsbyImage
                image={getImage(testimonial.document.data.image.localFile)}
                alt={testimonial.document.data.image.alt ? testimonial.document.data.image.alt : 'AC - Consulting'}
              />
            </div>
            <div className={scss.card__details__infos}>
              <span className={scss.card__details__infos__name}>{testimonial.document.data.name}</span>
              <span className={scss.card__details__infos__job}>{testimonial.document.data.job} <span>@{testimonial.document.data.entreprise}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
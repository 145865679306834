import React, { useLayoutEffect, useRef, useEffect }from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { useBreakpoints } from 'hooks/useMediaQuery'

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IContentBackgroundImageMap } from './ContentBackgroundImageMap.types'
import * as scss from './ContentBackgroundImageMap.module.scss'

const ContentBackgroundImageMap: React.FC<IContentBackgroundImageMap> = ((props) => {
  const {
    id,
    primary: {
      content,
      image,
      image_mobile
    },
  } = props.data

  if (!content.richText.length) return
  const refRoot = useRef<HTMLDivElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)
  const refImage = useRef<HTMLDivElement>(null)
  const { isLg } = useBreakpoints()

  const createScrollTrigger = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `ContentBackgroundImageMap ${id}`,
        trigger: refWrapper.current,
        start: '-200px center',
        end: 'center center',
        scrub: 1,
      }
    })
    tl.to(refWrapper.current, { scale: 1.0 },0)
    tl.to(refImage.current, { filter: 'grayscale(0)' },0)
    setTimeout(() => {
      tl.scrollTrigger.refresh()
    }, 500)
  }

  useEffect(() => {
    isLg && createScrollTrigger()
    return () => isLg && ScrollTrigger.getById(`ContentBackgroundImageMap ${id}`).kill()
  }, [])

  console.log(image_mobile)
  return (

    <div
      className={`${scss.root}`}
      ref={refRoot}
    >
      <div className={`${scss.wrapper}`} ref={refWrapper}
      >
        <div>
          <div className={scss.innerWrapper}>
            <DefaultRichText text={content}/>
          </div>
        </div>
          <div className={scss.image_mobile} ref={refImage}>
            <GatsbyImage
              className={scss.gatsbyImage}
              image={getImage(image_mobile.localFile)}
              alt={image_mobile.alt ? image_mobile.alt : 'AC - Consulting'}
            />
          </div>
          <div className={scss.image_desktop} ref={refImage}
                      style={{
                        backgroundImage: `url(${image.url})`
                      }}
                      >
            {/* <GatsbyImage
              className={scss.gatsbyImage}
              image={getImage(image.localFile)}
              alt={image.alt ? image.alt : 'AC - Consulting'}
            /> */}
          </div>
      </div>
    </div>
  )
})

export { ContentBackgroundImageMap }

import React, { useRef } from 'react'
import { useStoreOverlayMenu } from 'state/Global'
import { DefaultLink } from 'components/common/defaultLink/DefaultLink'
import { INavLink } from './../NavLink.types'
import * as scss from "./NavLink.overlay.module.scss"

const NavLinkOverlay: React.FC<INavLink> = (({
  data: {
    primary: {
      link_label,
      link_url
    },
  }
}) => {
  const { overlayOpen, SET_OVERLAY } = useStoreOverlayMenu()

  const refTitle = useRef<HTMLDivElement>(null)

  const handleMouseEnter = () => {
    document.documentElement.style.setProperty('--o-inactive', "0.48")
    refTitle.current.setAttribute('data-h', 'true')
  }
  const handleMouseLeave = () => {
    document.documentElement.style.setProperty('--o-inactive', "1.0")
    refTitle.current.setAttribute('data-h', 'false')
  }
  const handleClick = () => {
    SET_OVERLAY(overlayOpen ? false : true)
  }

  return (
    <div
      ref={refTitle}
      className={scss.root}
      data-h='false'
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DefaultLink
        link_url={link_url}
        link_label={link_label}
      />
    </div>
  )
})

export { NavLinkOverlay }
// extracted by mini-css-extract-plugin
export var card = "TitleRowContent-module--card--69023";
export var card__content = "TitleRowContent-module--card__content--bdf01";
export var card__index = "TitleRowContent-module--card__index--b2c1b";
export var card__link = "TitleRowContent-module--card__link--6ad27";
export var cards = "TitleRowContent-module--cards--d4cbb";
export var cards__wrapper = "TitleRowContent-module--cards__wrapper--ae19a";
export var content = "TitleRowContent-module--content--c6fe6";
export var follower = "TitleRowContent-module--follower--1eddc";
export var follower__wrapper = "TitleRowContent-module--follower__wrapper--17262";
export var mobileLink = "TitleRowContent-module--mobileLink--cbd26";
export var root = "TitleRowContent-module--root--98fb6";
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  useState,
  IframeHTMLAttributes
} from 'react'
import gsap from 'gsap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IMobile, TCardHandle } from '../TestimonialsRowVideos.types'
import * as scss from './Mobile.module.scss'
import ReactPlayer from 'react-player'
const Mobile: React.FC<IMobile> = ({ items, id }) => {
  
  const refsCards = useRef<any>([])
  const refsCardsVideos = useRef<any>([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const wrapIndex = gsap.utils.wrap(0, items.length, currentSlide)

  const refCard = useRef<HTMLDivElement>(null)
  const refVideoWrapper = useRef<HTMLDivElement>(null)
  const refPlayButton = useRef<HTMLDivElement>(null)
  const refVideo = useRef<HTMLIFrameElement>(null)
  const [play, setPlay] = useState(false)

  const handlePlay = () => {
    // if (!play) return
    setPlay(play => play ? false : true)
    // setPlay(false)

  }

  const handlePlayPlaceHolder = () => {
    // setPlay(play => play ? false : true)
    setPlay(true)

  }

  const startVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 0, pointerEvents: 'none'},0)
    timeline.add(() => {
      // refsCards.current[wrapIndex].playsInline= true
      // refsCards.current[wrapIndex].play()
      // refsCards.current[wrapIndex].muted = false
    },0)
  }

  const resetVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 1, pointerEvents: 'all'},0)
    timeline.add(() => {
      refsCardsVideos.current[wrapIndex].src = items[wrapIndex].testimonial.document.data.link_cdn.url
      // refsCards.current[wrapIndex].playsInline= false
      // refsCards.current[wrapIndex].pause()
      // refsCards.current[wrapIndex].currentTime = 0
      // refsCards.current[wrapIndex].muted = true
    },0)
  }

  const pauseVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 1, pointerEvents: 'all'},0)
    timeline.add(() => {
      refsCardsVideos.current[wrapIndex].src = items[wrapIndex].testimonial.document.data.link_cdn.url
      // refsCards.current[wrapIndex].pause()
      // refsCards.current[wrapIndex].muted = false
      // refsCards.current[wrapIndex].currentTime = 0
    },0)
  }


  useEffect(() => {
    play ? startVideo() : pauseVideo()
  }, [play])

  useEffect(() => {
    gsap.set(refsCards.current[wrapIndex], { autoAlpha: 1 })
  }, [wrapIndex])
  
  useEffect(() => {
    gsap.set(refsCards.current[wrapIndex], { autoAlpha: 1, pointerEvents: 'all' })

    // refVideoWrapper.current.addEventListener('click', handlePlay)
    // refPlayButton.current.addEventListener('click', handlePlayPlaceHolder)
    return () => {
      // refVideoWrapper && refVideoWrapper.current && refVideoWrapper.current.removeEventListener('click', handlePlay)
      // refPlayButton.current.removeEventListener('click', handlePlayPlaceHolder)
    }
  }, [wrapIndex])

  const next = () => {
    if (play) {
    }
    resetVideo()
    gsap.set(refsCards.current[wrapIndex], { autoAlpha: 0 })
    setCurrentSlide(c => c + 1)
  }
  const prev = () => {
    if (play) {
    }
    resetVideo()
    gsap.set(refsCards.current[wrapIndex], { autoAlpha: 0 })
    setCurrentSlide(c => c - 1)
  }

  return (
    <div className={scss.root}>
      <div className={scss.wrapper}>
        <div
          className={scss.video}
          // onClick={handlePlay}
          ref={refVideoWrapper}
        >
          { 
            items.map(({ testimonial }, index) => 
            <div className={scss.videoWrapper} ref={(r) => (refsCards.current[index] = r)} key={`TestimonialsRowVideos--mobile--${id}--${index}`}>
              <iframe ref={(r) => (refsCardsVideos.current[index] = r)} src={testimonial.document.data.link_cdn.url} />

                    {/* <video  id="introVideo" width="100%" height="100%" controls="controls" playsInline>
                      <source src={`${testimonial.document.data.link_cdn.url}#t=0.001`} type="video/mp4"/>
                    </video> */}
                  {/* <source src={testimonial.document.data.link_cdn.url} type="video/mp4"/> */}
                  {/* { testimonial.document.data.link_cdn && testimonial.document.data.link_cdn.url && <source src={`${testimonial.document.data.link_cdn.url}#t=0.001`} type="video/mp4"/> } */}
                {/* <ReactPlayer
                  ref={(r) => (refsCards.current[index] = r)}
                  url={testimonial.document.data.link_cdn.url}
                /> */}
            </div>
            )
          }

            {/* <div className={scss.video__placeholder}>
              <GatsbyImage
                className={scss.gatsbyImage}
                image={getImage(items[wrapIndex].testimonial.document.data.video_placeholder.localFile)}
                alt={items[wrapIndex].testimonial.document.data.video_placeholder.alt ? items[wrapIndex].testimonial.document.data.video_placeholder.alt : 'AC - Consulting'}
              />
            </div> */}
            {/* <span
              className={scss.video__playButton}
              ref={refPlayButton}
              onClick={handlePlayPlaceHolder}
            /> */}
          </div>
        </div>

      <div className={scss.content}>
        <div className={scss.content__quote}>
          <span/>
          <span>
            <DefaultRichText text={items[wrapIndex].testimonial.document.data.quote_1}/>
          </span>
          <span/>
        </div>
        <div className={scss.content__details}>
          <div className={scss.content__details__avatar}>
            <GatsbyImage
              className={scss.gatsbyImage}
              image={getImage(items[wrapIndex].testimonial.document.data.image.localFile)}
              alt={items[wrapIndex].testimonial.document.data.image.alt ? items[wrapIndex].testimonial.document.data.image.alt : 'AC - Consulting'}
            />
          </div>
          <div className={scss.content__details__infos}>
            <span className={scss.content__details__infos__name}>{items[wrapIndex].testimonial.document.data.name}</span>
            <span className={scss.content__details__infos__job}>{items[wrapIndex].testimonial.document.data.job}</span>
          </div>
        </div>
        <div className={scss.navigation}>
          <span
            onClick={prev}
            className={scss.navigation__prev}
          />
          <span
            onClick={next}
            className={scss.navigation__next}
          />
        </div>
      </div>

    </div>
  )
}

export { Mobile }

import React, { Fragment, useEffect } from 'react'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"
import { useBreakpoints } from 'hooks/useMediaQuery'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import backgroundShape from 'assets/images/background-shape-noir-light.svg'
import { IHorizontalSlideShow } from './HorizontalSlideShow.types'
import * as scss from './HorizontalSlideShow.module.scss'

const HorizontalSlideShow: React.FC<IHorizontalSlideShow> = ((props) => {
  const {
    id,
    primary: {
      content,
    },
    items
  } = props.data

  if ( !items.length ) return
  const { isLg } = useBreakpoints()
  const slideshow1 = items.slice(0, items.length / 2)
  const slideshow2 = items.slice(items.length / 2, items.length)

  useEffect(() => {
    document.documentElement.style.setProperty(`--${id}-length`, `${items.length}`)
  }, [])

  return (
    <div className={`${scss.root}`}>
      <div className={ `${scss.wrapper} ${!content.richText.length && scss.wrapper__center}`}>
        <div className={ `${scss.wrapper__inner} g-width`}>
          {
            content.richText.length > 0 && (
              <div className={`${scss.content}`}>
                <DefaultRichText text={content}/>
              </div>
            )
          }

          <div className={scss.slideshow}>

            <div className={`${scss.slideshow__top}`}>
              <Marquee  direction='left' gradient={false}>
                <MarqueeGroup items={slideshow1} parentIndex="1" id={id}/>
              </Marquee>
            </div>

            <div>
              <Marquee direction='right' gradient={false}>
                <MarqueeGroup items={slideshow2} parentIndex="1" id={id}/>
              </Marquee>
            </div>

          </div>
        </div>

        <div className={`${scss.background} `}>
          {
            isLg ? (
              <img
                className={scss.background__image}
                src={backgroundShape}
                alt="AC Consulting"
              />
            ) : 
            (
              <StaticImage
                layout='fullWidth'
                className={scss.background__image}
                src="../../../../../assets/images/background-shape-noir-light.svg"
                alt="AC Consulting"
              /> 
            )
          }
        </div>
      
      </div>
    </div>
  )
})

export { HorizontalSlideShow }

const MarqueeGroup = ({ items, parentIndex, id }) => {
  return (
    <Fragment>
      {
        items.map(({ image, image_color }, index) => {
          return (
            <div
              key={`HorizontalSlideShow--${parentIndex}--${id}--slideshow1--${index}`}
              className={scss.slide}
            >
              <div className={scss.slide__image}>
                <img src={image.url} />
                {/* <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={image.alt ? image.alt : 'AC - Consulting'}
                /> */}
              </div>
              <div className={scss.slide__imageColor}>
                <img src={image_color.url} />
                {/* <GatsbyImage
                  image={getImage(image_color.localFile)}
                  alt={image_color.alt ? image_color.alt : 'AC - Consulting'}
                /> */}
              </div>
            </div>
          )
        })
      }
    </Fragment>
  )
}
// extracted by mini-css-extract-plugin
export var header = "Header-module--header--69346";
export var header__wrapper = "Header-module--header__wrapper--00a9c";
export var links = "Header-module--links--254a5";
export var links__dot = "Header-module--links__dot--25b5f";
export var links__title = "Header-module--links__title--a0de8";
export var links__title__close = "Header-module--links__title__close--59ae9";
export var links__title__menu = "Header-module--links__title__menu--8fbc8";
export var links__wrapper = "Header-module--links__wrapper--4df0b";
export var logo = "Header-module--logo--8095d";
export var root = "Header-module--root--d7a15";
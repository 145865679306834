import React, { useEffect, useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStoreOverlayMenu } from 'state/Global'
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { RichText } from 'prismic-reactjs'
import { ICircleLogosContent } from './CircleLogosContent.types'
import * as scss from './CircleLogosContent.module.scss'
import { useBreakpoints } from 'hooks/useMediaQuery'

const CircleLogosContent: React.FC<ICircleLogosContent> = ((props) => {
  const {
    id,
    primary: {
      reverse,
      animated_background,
      link_nav_item_uid,
      link_label,
      light_dark,
      content
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return
  const { isLg } = useBreakpoints()
  const logosCircle1 = items.slice(0, 5);
  const logosCircle2 = items.slice(5, 9);
  const logosCircle3 = items.slice(9, items.length);
  const refRoot = useRef<HTMLDivElement>(null)
  const refCircle1 = useRef(null)
  const refCircle1Logo = useRef([])
  const refCircle2 = useRef(null)
  const refCircle2Logo = useRef([])
  const refCircle3 = useRef(null)
  const refCircle3Logo = useRef([])
  const { overlayOpen, open, SET_OPEN, SET_OVERLAY, SET_NAVITEM_OVERLAY_TARGET } = useStoreOverlayMenu()

  const circle2Dot = [...Array(6)].map((item, index) => <span key={`CircleLogosContent__${id}__circle2--${index}`} className={`${scss.circles__circle2} ${scss.dot}`} data-dot-index={index}/>)
  const circle1Dot = [...Array(3)].map((item, index) => <span key={`CircleLogosContent__${id}__circle1--${index}`} className={`${scss.circles__circle1} ${scss.dot}`} data-dot-index={index}/>)

  useEffect(() => {
    gsap.to(refCircle1.current, { rotate: '360', repeat: -1, duration: 100, ease: "none" })
    gsap.to(refCircle1Logo.current, { rotate: '-360', repeat: -1, duration: 100, ease: "none" })
    gsap.to(refCircle2.current, { rotate: '360', repeat: -1, duration: 80, ease: "none"})
    gsap.to(refCircle2Logo.current, { rotate: '-360', repeat: -1, duration: 80, ease: "none" })
    gsap.to(refCircle3.current, { rotate: '360', repeat: -1, duration: 60, ease: "none"})
    gsap.to(refCircle3Logo.current, { rotate: '-360', repeat: -1, duration: 60, ease: "none" })
  }, [])

  const createScrollTrigger = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `scroll ${id}`,
        trigger: refRoot.current,
        start: 'top top',
        end: 'bottom center',
        scrub: 1,
      }
    })
    !light_dark && tl.to(refRoot.current, { scale: 0.95, borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px' })
    light_dark && tl.to(refRoot.current, { scale: 0.95, borderRadius: '40px'})
    ScrollTrigger.refresh()
  }

  const handleClick = () => {
    SET_OVERLAY(true)
    SET_NAVITEM_OVERLAY_TARGET(link_nav_item_uid)
  }

  useLayoutEffect(() => {
    isLg && createScrollTrigger()
    return () => isLg && ScrollTrigger.getById(`scroll ${id}`).kill()
  }, [])

  return (
    <div className={`${scss.root} ${light_dark && scss.root__dark}`} ref={refRoot}>
      <div className={ `${scss.wrapper} ${reverse && scss.wrapper__reverse}`}>

        <div className={scss.circles}>
          <span className={scss.circles__circle1} >
            <div ref={refCircle1} className={scss.circles__wrapper}>
              { circle1Dot }
              {
                logosCircle1.map(({ image }, index) => (
                  <span
                    key={`CircleLogosContent__${id}__circle1__logo--${index}`}
                    data-index={index}
                    ref={(r) => (refCircle1Logo.current[index] = r)}
                    className={`${scss.circles__circle1__logo} ${scss.logo}`}
                    >
                    <GatsbyImage
                      image={getImage(image.localFile)}
                      alt={image.alt ? image.alt : 'AC - Consulting'}
                    />
                  </span>
                ))
              }
            </div>
          </span>
          <span className={scss.circles__circle2}>
            <div ref={refCircle2} className={scss.circles__wrapper}>
              { circle2Dot }
              {
                logosCircle2.map(({ image }, index) => (
                  <span
                    key={`CircleLogosContent__${id}__circle2__logo--${index}`}
                    data-index={index}
                    ref={(r) => (refCircle2Logo.current[index] = r)}
                    className={`${scss.circles__circle2__logo} ${scss.logo}`}
                  >
                    <GatsbyImage
                      image={getImage(image.localFile)}
                      alt={image.alt ? image.alt : 'AC - Consulting'}
                    />
                  </span>
                ))
              }
            </div>
          </span>

          <span className={scss.circles__circle3}>
            <div ref={refCircle3} className={scss.circles__wrapper}>
              {
                logosCircle3.map(({ image }, index) => (
                  <span
                    key={`CircleLogosContent__${id}__circle3__logo--${index}`}
                    data-index={index}
                    ref={(r) => (refCircle3Logo.current[index] = r)}
                    className={`${scss.circles__circle3__logo} ${scss.logo}`}
                    >
                    <GatsbyImage
                      image={getImage(image.localFile)}
                      alt={image.alt ? image.alt : 'AC - Consulting'}
                    />
                  </span>
                ))
              }
            <span className={`${scss.circles__circle3__dot1} ${scss.dot}`}/>
              </div>
          </span>

          <span className={scss.circles__circle4}/>
        </div>

        <div className={scss.content}>
          <div>
            <div className={scss.content__top}>
              <DefaultRichText text={content}/>
            </div>
            {
              link_label && link_label.richText.length > 0 && (<div className={scss.cta} onClick={handleClick}><span><span><DefaultRichText text={link_label}/></span></span></div>)
            }
            
          </div>
        </div>
        
      </div>
    </div>
  )
})

export { CircleLogosContent }

import create from "zustand";
import { IGlobal } from './Global.types'

const storeOverlayMenu = (set: Function) => {
  return {
    overlayOpen: false,
    timeline: null,
    currentLang: 'fr-fr',
    altLangs: [],
    open: {
      status: false,
      id: ''
    },
    navItemsOverlay: [],
    navItemOverlayTarget: null,
    SET_CURRENT_LANG: (currentLang: string) => set((state: IGlobal) => ({
      ...state,
      currentLang
    })),
    SET_ALT_LANG: (altLang: string) => set((state: IGlobal) => ({
      ...state,
      altLang
    })),
    SET_NAVITEM_OVERLAY_TARGET: (navItemOverlayTarget: string) => set((state: IGlobal) => ({
      ...state,
      navItemOverlayTarget
    })),
    SET_TIMELINE: (timeline: string) => set((state: IGlobal) => ({
      ...state,
      timeline
    })),
    SET_OPEN: (open: string) => set((state: IGlobal) => ({
      ...state,
      open
    })),
    SET_OVERLAY: (overlayOpen: string) => set((state: IGlobal) => ({
      ...state,
      overlayOpen
    })),
  }
}

const useStoreOverlayMenu = create<any>((set: Function) => ({
  ...storeOverlayMenu(set),
}))

export {
  useStoreOverlayMenu
}
  
import RAF from "./raf"
import Lenis from '@studio-freight/lenis'
import ScrollTrigger from "gsap/ScrollTrigger"

interface Scroll  {
  lenis: any | Lenis
  ScrollContainer: HTMLElement
  isSet: boolean
}

class Scroll {

  constructor() {
    this.update = this.update.bind(this)
    this.isSet = false
  }

  init() {
    
    this.lenis = new Lenis({
      // duration: 1.2,
      // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      // direction: 'vertical',
      // gestureDirection: 'vertical',
      smooth: true,
      // mouseMultiplier: 1,
      // smoothTouch: false,
      // touchMultiplier: 2,
      // infinite: false,
    })

    // RAF.subscribeScroll(this.update)

    const raf = (time) => {
      this.lenis.raf(time)
      requestAnimationFrame(raf)
    }
    
    requestAnimationFrame(raf)

    this.isSet = true
  }

  disable() {
  }

  update(time) {
    this.lenis.raf(time * 1000)
  }

  reset() {
  }

  resize() {
  }

}

const _instance = new Scroll()
export default _instance
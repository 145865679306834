import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form"
import axios from "axios"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import FormSliceZone from '../../FormSliceZone'
import { IContentForm } from './ContentForm.types'
import * as scss from './ContentForm.module.scss'
import SCROLL from 'utils/scroll'

const ContentForm: React.FC<IContentForm> = ((props) => {
  const {
    id,
    primary: {
      content,
      content_success,
      form
    },
  } = props.data
  const refForm = useRef(null)
  const refRoot = useRef(null)
  const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      disciplines: []
    }
  })
  const [sucessForm, setSuccesForm] = useState(false)
  const [serverState, setServerState] = useState({
   submitting: false,
   status: null
 })

 const refSuccess = useRef()

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      refForm.current.reset()
    }
  }

  const onClose = () => {
    setSuccesForm(false)
  }

  const onSubmit = data => {
    console.log('submit', data, form.document.data)
    setServerState({ submitting: true, status: null })
    setSuccesForm(true)
    SCROLL.lenis.scrollTo(refRoot.current.getBoundingClientRect().top + window.innerHeight)

    axios({
      method: "post",
      url: form.document.data.form_url,
      data: data
    })
      .then(r => {
        // console.log(r)
        handleServerResponse(true, "Thanks!", data)

      })
      .catch(r => {
        // console.log(r)
        handleServerResponse(false, r.response.data.error, data)
      })
  }

  return (
    <div className={`${scss.root}`} ref={refRoot}>
      <div className={`${scss.wrapper} g-width`}>
        <div className={scss.content}><DefaultRichText text={content}/></div>
        {sucessForm && (
            <div className={scss.success_wrapper} ref={refSuccess}>
              <span className={scss.success}>
                <div><DefaultRichText text={content_success}/></div>
                <span className={scss.success__close} onClick={onClose}><span><span>OK</span></span></span>
              </span>
            </div>
          )}
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            ref={refForm}
            className={sucessForm ? `${scss.form} ${scss.form__success}`: scss.form}
            // className={sucessForm ? (scss.form, scss.form__success): scss.root__form}
          >
            {/* <input defaultValue="test" {...register("example")} />
            <input type="submit" /> */}
            <FormSliceZone data={form.document.data.body} register={register} errors={errors} control={control}/>
          </form>
        </div>
      </div>
    </div>
  )
})

export { ContentForm }
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  useState
} from 'react'
import gsap from 'gsap'
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IDesktop, TCardHandle } from '../TestimonialsRowVideos.types'
import * as scss from './Desktop.module.scss'

const Desktop: React.FC<IDesktop> = ({ items, id }) => {
  
  const refsCards = useRef<any>([])
  const refWrapper = useRef<HTMLDivElement>(null)
  const refInnerWrapper = useRef<HTMLDivElement>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const wrapIndex = gsap.utils.wrap(0, items.length, currentSlide)

  const next = () => {
    refsCards.current[wrapIndex].animateOut()
    setCurrentSlide(c => c + 1)
  }
  const prev = () => {
    refsCards.current[wrapIndex].animateOut()
    setCurrentSlide(c => c - 1)
  }
  const goToItem = (index: number) => {
    refsCards.current[wrapIndex].animateOut()
    setCurrentSlide(index)
  }

  useEffect(() => {
    refsCards.current[wrapIndex].animateIn()
    refWrapper.current.scrollTo({
      left: ((wrapIndex - 1) * 153),
      behavior: 'smooth',
    })
  }, [wrapIndex])

  useEffect(() => {
    gsap.set(refWrapper.current, { width: `${( (153 + 24) * 3) + 620}px` })
    gsap.set(refInnerWrapper.current, { width: `${( (153 + 24) * (items.length - 1)) + 620}px` })
  }, [])

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    variableWidth: true,
  }

  return (
    <div className={`${scss.root}`}>

      <div className={`${scss.wrapper}`} ref={refWrapper}>
        <div className={scss.wrapper__inner} ref={refInnerWrapper}>
            { 
              items.map(({ testimonial }, index) => 
                <Card
                  key={`TestimonialsRowVideos--${id}--${index}`}
                  ref={(r) => (refsCards.current[index] = r)}
                  index={index}
                  testimonial={testimonial}
                  goToItem={goToItem}
                />
              )
            }
        </div>
      </div>
      <div className={scss.navigation}>
        <span
          onClick={prev}
          className={scss.navigation__prev}
        />
        <span
          onClick={next}
          className={scss.navigation__next}
        />
      </div>

      <div className={scss.content}>
        <div className={scss.content__quote}>
            <DefaultRichText text={items[wrapIndex].testimonial.document.data.quote_1}/>
        </div>
        <div className={scss.content__details}>
          <div className={scss.content__details__avatar}>
            <GatsbyImage
              className={scss.gatsbyImage}
              image={getImage(items[wrapIndex].testimonial.document.data.image.localFile)}
              alt={items[wrapIndex].testimonial.document.data.image.alt ? items[wrapIndex].testimonial.document.data.image.alt : 'AC - Consulting'}
            />
          </div>
          <div className={scss.content__details__infos}>
            <span className={scss.content__details__infos__name}>{items[wrapIndex].testimonial.document.data.name}</span>
            <span className={scss.content__details__infos__job}>{items[wrapIndex].testimonial.document.data.job} <span>@{items[wrapIndex].testimonial.document.data.entreprise}</span></span>
          </div>
        </div>
      </div>

    </div>
  )
}

const Card: ForwardRefExoticComponent<TCardHandle> = forwardRef((props, ref) => {
  const {
    document: {
      data: {
        job,
        name,
        entreprise,
        video_placeholder,
        video_mp4,
        video_webm,
        link_cdn
      }
    }
  } = props.testimonial

  const {
    goToItem,
    index
  } = props

  const refCard = useRef<HTMLDivElement>(null)
  const refPlayButton = useRef<HTMLDivElement>(null)
  const refVideo = useRef<HTMLIFrameElement>(null)
  const refVideoWrapper = useRef<HTMLDivElement>(null)
  const [play, setPlay] = useState(false)
  const [active, setActive] = useState(false)

  useImperativeHandle(ref, () => ({
    root: refCard.current,
    animateIn: animateIn,
    animateOut: animateOut,
  }))

  const animateIn = () => {
    refCard.current.classList.add(scss.card__active)
    setActive(true)
  }

  const animateOut = () => {
    gsap.set(refPlayButton.current, { clearProps: 'all' })
    console.log('click')
    refVideo.current.src = link_cdn.url

    if (play) {
    console.log('click')

      refVideo.current.src = link_cdn.url
      // refVideo.current.src += '&autoplay=1'
      gsap.set(refPlayButton.current, { clearProps: 'all' })
      gsap.to([refVideoWrapper.current, refVideo.current], { borderRadius: '40px'})
      setPlay(false)
    }
    setActive(false)
    refCard.current.classList.remove(scss.card__active)
  }

  const handlePlay = () => {
    if (!active) return
    setPlay(play => play ? false : true)
  }

  const handleNavigation = () => {
    if (active) return
    goToItem(index)
  }

  const startVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 0, pointerEvents: 'none'},0)
    timeline.to([refVideoWrapper.current, refVideo.current], { borderRadius: '0px'},0)
    timeline.set(refVideo.current, { pointerEvents: 'all' })
    timeline.add(() => {
      // refVideo.current.src += '&autoplay=1'
    },0)
  }

  const resetVideo = () => {
    const timeline = gsap.timeline()
    timeline.to(refPlayButton.current, { autoAlpha: 1, pointerEvents: 'all'},0)
    timeline.to([refVideoWrapper.current, refVideo.current], { borderRadius: '40px'},0)
    timeline.add(() => {
      refVideo.current.src = link_cdn.url
      // refVideo.current.currentTime = 0
    },0)
  }

  useEffect(() => {
    if (!active) return
    play ? startVideo() : resetVideo()
  }, [play])

  return (
    <div
      onClick={handleNavigation}
      className={scss.card}
      ref={refCard}
    >
      <div
        onClick={handlePlay}
        className={scss.card__video}
        ref={refVideoWrapper}
      >
        <iframe ref={refVideo} src={link_cdn.url} />
        {/* <video
          ref={refVideo}
          autoPlay={false}
          playsInline
          muted
        >
          { link_cdn && link_cdn.url && <source src={link_cdn.url} type="video/mp4"/> }
        </video> */}
        <div className={scss.card__video__placeholder}>
          <GatsbyImage
            className={scss.gatsbyImage}
            image={getImage(video_placeholder.localFile)}
            alt={video_placeholder.alt ? video_placeholder.alt : 'AC - Consulting'}
          />
        </div>
        {/* <span
          className={scss.card__video__playButton}
          ref={refPlayButton}
        /> */}
      </div>
      <div className={scss.card__content}>
        <div className={scss.card__content__name}>{name}</div>
        <div className={scss.card__content__job}>{job}<br/><span>@{entreprise}</span></div>
      </div>
    </div>
  )
})

export { Desktop }

import React, { Fragment } from "react"

import { ISliceZone } from "./SliceZone.types"
import { NavItem } from "./slices/NavItem/NavItem"
import { NavItemLang } from "./slices/NavItemLang/NavItem"
import { NavLink } from "./slices/NavLink/NavLink"
import { NavItemFooter } from "./slices/NavItemFooter/NavItemFooter"
import { Hero } from "./slices/Hero/Hero"
import { HeroVariation1 } from "./slices/Hero_variation_1/HeroVariation1"
import { HorizontalSlideShow } from "./slices/HorizontalSlideShow/HorizontalSlideShow"
import { CircleLogosContent } from "./slices/CircleLogosContent/CircleLogosContent"
import { RowContentIcon } from "./slices/RowContentIcon/RowContentIcon"
import { TitleRowContent } from "./slices/TitleRowContent/TitleRowContent"
import { ContentBackgroundImageMap } from "./slices/ContentBackgroundImageMap/ContentBackgroundImageMap"
import { TestimonialsRowVideos } from "./slices/TestimonialsRowVideos/TestimonialsRowVideos"
import { TestimonialsCardsSlider } from "./slices/TestimonialsCardsSlider/TestimonialsCardsSlider"
import { CtasBackgroundImage } from "./slices/CtasBackgroundImage/CtasBackgroundImage"
import { VerticalScrollingContentImage } from "./slices/VerticalScrollingContentImage/VerticalScrollingContentImage"
import { VerticalScrollingSlider } from "./slices/VerticalScrollingSlider/VerticalScrollingSlider"
import { ImageContent } from "./slices/ImageContent/ImageContent"
import { GridIconContentWithCta } from "./slices/GridIconContentWithCta/GridIconContentWithCta"
import { NumberContentColumn } from "./slices/NumberContentColumn/NumberContentColumn"
import { ContentVideosSlider } from "./slices/ContentVideosSlider/ContentVideosSlider"
import { ColumnIconContent } from "./slices/ColumnIconContent/ColumnIconContent"
import { FullWidthImageContentQuote } from "./slices/FullWidthImageContentQuote/FullWidthImageContentQuote"
import { ContentSquarePictures } from "./slices/ContentSquarePictures/ContentSquarePictures"
import { ContentForm } from "./slices/ContentForm/ContentForm"
import { Content } from "./slices/Content/Content"
import { RssFeedList } from "./slices/RssFeedList/RssFeedList"

const SliceZone = ( { data, variation } : ISliceZone ) => {

  const components = {
    "nav_item": NavItem,
    "nav_item_lang": NavItemLang,
    "nav_link": NavLink,
    "hero": Hero,
    "hero_variation_1": HeroVariation1,
    "horizontal_slideshow": HorizontalSlideShow,
    "circle_logos_content": CircleLogosContent,
    "row_content_icon": RowContentIcon,
    "title_row_content": TitleRowContent,
    "content_background_image_map": ContentBackgroundImageMap,
    "testimonials_row_videos": TestimonialsRowVideos,
    "testimonials_cards_slider": TestimonialsCardsSlider,
    "ctas_background_image": CtasBackgroundImage,
    "nav_item_footer": NavItemFooter,
    "vertical_scrolling_content_image": VerticalScrollingContentImage,
    "vertical_scrolling_slider": VerticalScrollingSlider,
    "image_content": ImageContent,
    "grid_icon_content_with_cta": GridIconContentWithCta,
    "number_content_column": NumberContentColumn,
    "content_videos_slider": ContentVideosSlider,
    "column_icon_content": ColumnIconContent,
    "full_width_image_content_and_quote": FullWidthImageContentQuote,
    "content_square_pictures": ContentSquarePictures,
    "content_form": ContentForm,
    "content": Content,
    "rss_feed_list": RssFeedList
  }
  
  const sliceZoneContent = data.map((slice, index) => {
    // console.log('=>', slice.slice_type, slice)
    const SliceComponent = components[slice.slice_type]
    if (SliceComponent) {
      return (
        <SliceComponent
          data={slice}
          key={`slice-${slice.id}`}
          id={slice.id}
          variation={variation}
        />
      )
    }
    return null
  })

  return <Fragment>{sliceZoneContent}</Fragment>
}

export default SliceZone
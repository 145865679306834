import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  useState
} from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import { useBreakpoints } from 'hooks/useMediaQuery'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { DefaultRichText } from 'components/common/defaultRichText/DefaultRichText'
import { IVerticalScrollingContentImage, IIllustration, IContent } from './VerticalScrollingContentImage.types'
import * as scss from './VerticalScrollingContentImage.module.scss'
gsap.registerPlugin(ScrollTrigger)

const VerticalScrollingContentImage: React.FC<IVerticalScrollingContentImage> = ((props) => {
  const {
    id,
    primary: {
      content,
      light_dark
    },
    items
  } = props.data

  if (
    !content.richText.length ||
    !items.length
  ) return

  const refsImages = useRef<any>([])
  const { isLg } = useBreakpoints()

  const handleOnEnter = (index) => {
    if (index > 0) {
      refsImages.current[index - 1] && refsImages.current[index - 1].hide()
      refsImages.current[index] && refsImages.current[index].show()
    }
  }

  const handleOnLeaveBack = (index) => {
    if (index > 0) {
      refsImages.current[index] && refsImages.current[index].hide()
      refsImages.current[index - 1] && refsImages.current[index - 1].show()
    }
  }


  return (
    <div className={`${scss.root} ${light_dark && scss.root__dark}`}>
      <div className={`g-width`}>

        <div className={`${scss.content} `}>
            <DefaultRichText text={content}/>
        </div>

        <div className={scss.verticalContent}>
          <div className={scss.verticalContent__scrollBar}><span className={scss.verticalContent__scrollBar__track}/></div>
          <div className={scss.verticalContent__content}>
            {
              items.map(({ content, image }, index) =>
                <Content
                  handleOnEnter={handleOnEnter}
                  handleOnLeaveBack={handleOnLeaveBack}
                  content={content}
                  image={image}
                  ref={(r) => (refsImages.current[index] = r)}
                  index={index}
                  key={`VerticalScrollingContentImage--${id}__${index}`}
                />
              )
            }
          </div>
          <div className={scss.verticalContent__illustration}>
            <div className={scss.verticalContent__illustration__wrapper}>
              {
                items.map(({ image }, index) => 
                  <Illustration
                    image={image}
                    ref={(r) => (refsImages.current[index] = r)}
                    index={index}
                    key={`VerticalScrollingContentImage--illustration--${id}__${index}`}
                  />
                )
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
})

export { VerticalScrollingContentImage }

const Illustration: ForwardRefExoticComponent<IIllustration> = forwardRef((props, ref) => {
  const { image } = props

  const refRoot = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show
  }))

  const hide = () => gsap.to(refRoot.current, { autoAlpha: 0 })
  const show = () => gsap.to(refRoot.current, { autoAlpha: 1 })

  return (
    <div
      className={scss.verticalContent__illustration__image}
      ref={refRoot}
    >
      <GatsbyImage
        image={getImage(image.localFile)}
        alt={image.alt ? image.alt : 'AC - CONSULTING'}
      />
    </div>
  )
})

const Content: ForwardRefExoticComponent<IContent> = forwardRef((props, ref) => {
  const { content, image } = props

  const refRoot = useRef<HTMLDivElement>(null)
  const refScrollTrigger = useRef(null)

  const createScrollTrigger = () => {
    refScrollTrigger.current = ScrollTrigger.create({
      trigger: refRoot.current,
      start: () => `center center`,
      // end: 'center center',
      scrub: true,
      onEnter: () => { props.handleOnEnter(props.index) },
      onLeaveBack: () => { props.handleOnLeaveBack(props.index) },
      // markers: true
    })
  }

  useImperativeHandle(ref, () => ({
  }))

  useEffect(() => {
    createScrollTrigger()

    return () => refScrollTrigger.current.kill()
  }, [])

  return (
    <div ref={refRoot} className={scss.verticalContent__content__item}>
      <DefaultRichText text={content}/>
      <div className={scss.verticalContent__content__image}>
        <GatsbyImage
          image={getImage(image.localFile)}
          alt={image.alt ? image.alt : 'AC - CONSULTING'}
        />
      </div>
    </div>
  )
})


